import * as AuthConstants from 'auth/constants'
import { Loading } from 'components/loading'
import { WidgetsComponent } from 'components/widgets/dashboard/component'
import { RasaContext } from 'context'
import * as DateFormat from 'date-fns'
import { Dataset } from 'generic/dataset'
import * as React from 'react'
import { Roles } from 'shared/constants'
import { formatDateForES } from 'shared_server_client/dates'
import * as StoreConstants from 'store/constants'
import { AnnouncementInfo, User } from 'user'
import * as RasaCookies from '../../generic/cookies'

import { AllComponentPropsWithModal, registerNewComponentWithModals } from '../../generic/genericRedux'
import { isTruthy, SIGNUP_STEPS } from '../../generic/utility'
import './_styles.scss'
import { OnboardingDashboard } from './charts'
import { ALLOWED_SIGNUP_SOURCES } from './constants'
import { SENDER_REQUIRED_CONTACTS_COUNT } from './contacts'
import * as Modals from './modals'
import * as Utils from './utils'
import { Welcome } from './welcome'

type DashboardData = any
type DashboardProps = AllComponentPropsWithModal<DashboardData>
declare const RASA_NEW_ONBOARDING_BASE_URL: string
interface DashboardState {
  announcement: AnnouncementInfo,
  communityId: string,
  isSender: boolean,
  isLoading: boolean,
  userEmail: string,
}
class DashboardComponent extends React.Component<DashboardProps, DashboardState> {
  public static contextType = RasaContext
  constructor(props: DashboardProps) {
    super(props)

    this.state = {
      announcement: null,
      communityId: '',
      isSender: false,
      isLoading: true,
      userEmail: '',
    }
  }

  public render() {
    return this.state.isLoading
    ? <Loading size="32" />
    : this.state.isSender ? <WidgetsComponent /> : this.getDefaultDashboard()
  }

  public componentDidMount() {
    this.context.user.init().then((user: User) => {
      const announcement = user.announcements.length ? user.announcements[0] : null
      const isSuperUser: boolean = user.activeCommunity.role === Roles.super_admin
      let showAnnouncement: boolean = announcement &&
        !isTruthy(sessionStorage.getItem(Utils.getHideAnnouncementStorageKey(announcement.id))) &&
        (isSuperUser || !announcement.is_preview)
      this.setState({
        announcement,
        communityId: user.activeCommunity.communityId,
        isLoading: false,
        userEmail: user.person.email,
      })
      this.checkIfSender(user.activeCommunity.communityId)
      const state: any = this.context.store.getState()
      if ( Utils.isAppSumoSignup(state, user) ) {
        this.props.openModal(Modals.AppSumoWelcomeAboard.key, user)
      } else if (Utils.isIncompleteHubSpotAccount(state, user)) {
        this.props.openModal(Modals.HubSpotWelcomeAboard.key, user)
      }
      if (!isSuperUser && user.activeCommunity.data.account_options) {
        const accountOptions = JSON.parse(user.activeCommunity.data.account_options)
        if (accountOptions && ALLOWED_SIGNUP_SOURCES.includes(accountOptions.signup_source)
          && !accountOptions.skip_onboarding) {
          if (accountOptions.onboarding_status === SIGNUP_STEPS.COMPLETION) {
            showAnnouncement = false
            this.props.openModal(Modals.OnboardingModal.key, user)
          } else {
            this.sendToOnboarding(accountOptions.onboarding_status, this.state.userEmail)
          }
        }
      }
      if (showAnnouncement) {
        this.props.openModal(Modals.Announcement.key, announcement)
      }
    })
  }

  private sendToOnboarding = (step: string, email: string) => {
    const community = window.localStorage.getItem(StoreConstants.SELECTED_COMMUNITY)
    const cookie: RasaCookies.RasaCookie = {
      maxAge: 30,
      name: `t${Date.now()}`,
      value: window.localStorage.getItem(AuthConstants.RASA_AUTH_TOKEN),
    }
    RasaCookies.setCookie(cookie)
    let onboardingUrl = `${RASA_NEW_ONBOARDING_BASE_URL}/?${RasaCookies.SIGNUP_COOKIE_QUERY_PARAM}=${cookie.name}&SELECTED_COMMUNITY=${community}&onboarding_status=${step}`
    if (step === SIGNUP_STEPS.VERIFY_EMAIL) {
      const verificationCode = window.localStorage.getItem(StoreConstants.EMAIL_VERIFICATION_CODE)
      if (verificationCode) {
        window.localStorage.removeItem(StoreConstants.EMAIL_VERIFICATION_CODE)
        onboardingUrl += `&c=${verificationCode}`
      }
      if (email) {
        onboardingUrl += `&email=${email}`
      }
      window.location.href = onboardingUrl
    } else {
      window.location.href = onboardingUrl
    }
  }

  private checkIfSender(communityId) {
    this.getSenderRequiredCount().then((senderCount) => {
      new Promise((resolve, reject) => {
        const search = window.location.search
        const searchParams = new URLSearchParams(search)
        const isSenderQuery = searchParams.get('is_sender')
        if (isSenderQuery !== null) {
          resolve(isTruthy(searchParams.get('is_sender'))) // forced by query param
        }
        const params = [
          {param: 'minimumSent', value: senderCount},
          {param: 'startDate', value: formatDateForES(DateFormat.subDays(new Date(), 31))},
          {param: 'endDate', value: formatDateForES(new Date())},
          {param: 'pageSize', value: 1},
        ]
        new Dataset().loadCommunityDataset('communityIssues', communityId, params).then((issues) => {
          resolve(issues[0].length)
        })
      }).then((isSender: boolean) => this.setState({isSender}))
    })
  }

  private getSenderRequiredCount() {
    return Promise.resolve(SENDER_REQUIRED_CONTACTS_COUNT)
  }

  private getDefaultDashboard() {
    return <div className={`dashboard-wrapper fade-in one ${this.context.user.activeCommunity
        && this.context.user.activeCommunity.data
        && this.context.user.activeCommunity.data.community_account_suspended === 1
        && this.context.user.activeCommunity.role !== Roles.super_admin ? 'account-suspended' : ''}`}>
          <div>
            <Welcome/>
            </div>
            <div>
            <OnboardingDashboard />
            </div>
            <Modals.AppSumoWelcomeAboard data={this.props.modals} closeModal={this.props.closeModal}/>
            <Modals.HubSpotWelcomeAboard data={this.props.modals} closeModal={this.props.closeModal}/>
            <Modals.Announcement data={this.props.modals} closeModal={this.props.closeModal}/>
            <Modals.OnboardingModal data={this.props.modals} saveModal={this.props.saveModal}
              title="Hey, look what we found!"
              closeModal={this.props.closeModal}/>
          </div>
  }

}

export const Dashboard =
  registerNewComponentWithModals<DashboardData>(
    DashboardComponent,
    'dashboard',
    [Modals.Announcement.key, Modals.AppSumoWelcomeAboard.key,
      Modals.HubSpotWelcomeAboard.key, Modals.OnboardingModal.key],
    {})
