import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { RasaContext } from 'context'
import { HeaderComponent } from 'components/header/component'
import { Dataset, DatasetParam } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { Row } from 'reactstrap'
import ReactMarkdown from 'react-markdown'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

import './_styles.scss'

interface Message {
  content: string
  format: string
}
interface Campaign {
  id: number,
  messages: Message[],
  name: string,
  subject: string,
  is_active: boolean,
  contact_count: number,
  formats: string
}

const NO_CAMPAIGN: Campaign = {
  id: -1,
  messages: [],
  name: '',
  subject: '',
  is_active: false,
  contact_count: 0,
  formats: ''
}
const NO_MESSAGE: Message = {
  content: '',
  format: ''
}

interface State {
  campaigns: Campaign[]
  showModal: number,
  showModalType: string,
}

const emptyState: State = {
  campaigns: [],
  showModal: -1,
  showModalType: '',
}

class RexCampaignsComponent extends RasaReactComponent<any, State>{
  public static contextType = RasaContext;

  constructor(props: any) {
    super(props, "rexCampaigns", emptyState)
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      const params: DatasetParam[] = [
      ]
      return new Dataset().loadCommunityDataset(
        'campaigns',
        activeCommunity.communityId,
        params
      ).then((results) => {
        this.setState({
          campaigns: results
        })
      })
    })
  }

  public render = () => {
    return <div className="rex-campaign-demo">
      <HeaderComponent
        icon="t-rex"
        title={'t-rex Campaigns Demonstration'}
        subTitle='Let t-rex help you manage email campaigns!'
        description={[
          'View and manage email campaigns.',
        ]}
      />
      <Row className="webinar">
        { this.campaignsTable()}
      </Row>
      {this.previewModal()}
    </div>
  }

  private campaignsTable = () => {
    return <Grid data={this.state.campaigns}>
      <GridColumn field="name" title="Name" />
      <GridColumn field="subject" title="Subject" />
      <GridColumn field="contact_count" title="# Contacts"/>
      <GridColumn title="Text" cell={this.previewTextCell}/>
      <GridColumn title="HTML" cell={this.previewHtmlCell}/>
      <GridColumn field="formats" title="Formats"/>
    </Grid>
  }

  private previewTextCell = (props: any) => {
    return <td>
      <Button onClick={() => this.setState({ showModal: props.dataItem.id, showModalType: 'text' })}>
        Preview
      </Button>
    </td>
  }

  private previewHtmlCell = (props: any) => {
    return <td>
      <Button onClick={() => this.setState({ showModal: props.dataItem.id, showModalType: 'html' })}>
        Preview
      </Button>
    </td>
  }

  protected previewModal = () => {
    const campaign = this.state.campaigns.filter((c: Campaign) => c.id === this.state.showModal)[0] || NO_CAMPAIGN
    const message = campaign.messages.filter((m: Message) => m.format === this.state.showModalType)[0] || NO_MESSAGE
    return <div className="generate-email-modal">
        <Modal isOpen={this.state.showModal >= 0} className="generate-email-modal">
          <ModalBody>
            {campaign.subject.length > 0 && <h3>{campaign.subject}</h3>}
            <div className="email-content">
              { message.format === 'html'
              ? <div dangerouslySetInnerHTML={{__html: message.content}}/>
              : <ReactMarkdown>{message.content}</ReactMarkdown>
              }
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.setState({ showModal: -1, showModalType: '' })}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  }


}

export const RexCampaigns = GenericRedux.createConnect(
  RexCampaignsComponent,
  "rex_campaigns",
)
