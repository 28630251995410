import { AjaxWrapper, HttpMethod } from '../../../generic/ajaxWrapper';
import React, {Component} from 'react'
import { InputSearch } from '../../input-search/component';
import { RasaContext } from 'context'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { Loading } from '../../loading';

export interface CustomField {
  name: string,
  value: string,
  type: string,
}

interface State {
  customFields: CustomField[],
  error?: string,
  selectCustomField: string,
  isLoading: boolean,
  communityId: string,
}

interface Props {
  id: string,
  label?: string,
  onChanged?: (e) => void,
}

export class FieldMapping extends Component<Props, State> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: any) {
    super(props)
    this.state = {
      isLoading: true,
      customFields: [],
      selectCustomField: null,
      communityId: null,
    }
    this.loadData = this.loadData.bind(this)
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
      .then((activeCommunity) => {
        this.setState({
          communityId: activeCommunity.communityId,
        }, () => this.loadData())
      })
  }

  public render() {
    return this.state.isLoading ? <Loading size="32"/> :
      (
        <div className="filter-wrapper">
          <div className="filter-name-wrapper">
            <div>
              <div className="field-label-credentials">
                {this.props.label || 'Custom Field'}
              </div>
              {this.state.error ?
                <div className="contact-client-success">
                  {this.state.error}
                </div> :
                this.state.customFields.length > 0 &&
                <InputSearch
                  placeholder={this.props.label || 'Custom Field'}
                  data={this.state.customFields}
                  type="search"
                  value={this.state.customFields.find((a) => a.value === this.state.selectCustomField)}
                  textFiled="name"
                  onChange={(e) => this.handleSelect(e)}/>
              }
            </div>
          </div>
        </div>
      )
  }

  private handleSelect = (e) => {
    this.setState({
      selectCustomField: e.value,
    })
    if(this.props.onChanged){
      this.props.onChanged(e)
    }
  }
  private loadData = () => {
    const url: string = `${AjaxWrapper.getServerUrl()}/integrations/${this.state.communityId}/custom-fields/${this.props.id}`
    AjaxWrapper.ajax(url, HttpMethod.GET, null).then((result) => {
      this.setState({
        customFields: result,
        isLoading: false
      })
    }).catch((err) => {
      this.setState({
        isLoading: false,
        error: 'Something went wrong, please contact our Support Team.',
      })
    })
  }
}
