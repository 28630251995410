import { AjaxStreamCallback, AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import {
  ChatPayload,
  ChatResponse,
  GenerationType,
 } from 'shared_server_client/types/generative_ai'

export type ChatCallback = (response: ChatResponse) => void

export const streamRexResponse = (
  communityIdentifier: string,
  payload: ChatPayload,
  generationType: GenerationType,
  callback: ChatCallback) => {
  const textUrl: string =
    `${AjaxWrapper.getServerUrl()}/ai/${communityIdentifier}/stream/${generationType}`
  const responseParser: AjaxStreamCallback = (success: boolean, chunk: string) => {
    if ( !chunk ) {
      callback(null)
    } else if ( !success ) {
      callback({
        done: true,
        error: chunk,
        message: chunk,
      })
    } else {
      const jsonString: string = '[' + chunk.replace(/}{/g, '},{') + ']'
      try {
        JSON.parse(jsonString).forEach((r: ChatResponse) => {
          callback(r)
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('ERROR parsing ' + jsonString)
      }
    }
  }
  AjaxWrapper.stream(textUrl, HttpMethod.POST, { ...payload, stream: true}, responseParser)
}

export const getRexResponse = (communityId: string, payload: ChatPayload): Promise<ChatResponse> => {
  const textUrl: string = `${AjaxWrapper.getServerUrl()}/ai/${communityId}/${payload.aiPromptType}`
  return AjaxWrapper.ajax(textUrl, HttpMethod.POST, payload)
}
