import { DropdownComponent, DropdownOption, OnChangeEvent } from 'components/dropdown/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as React from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { ChatPayload, ChatResponse, GenerationType } from 'shared_server_client/types/generative_ai'

import { copyToClipboard } from '../../generic/utility'

import * as Constants from './constants'
import * as Data from './data'
import * as Types from './types'

export interface Props {
  callback?: Types.EmailCallback,
  communityIdentifier: string,
  prompts: string[],
  aiPromptType?: string,
  responsesTitle?: string,
  selectors?: Types.SelectorType[],
}

const EMPTY_EMAIL: Types.Email = {
  textBody: '',
  subject: '',
  htmlBody: '',
}

export interface State {
  emoji: DropdownOption,
  error: string,
  format: DropdownOption,
  isGenerating: boolean,
  language: DropdownOption,
  length: DropdownOption,
  showHtmlModal: boolean,
  title: string,
  tone: DropdownOption,
  url: string,
  versionDisplay: number,
  versionGenerate: number,
  versions: Types.Email[],
}

export const baseEmptyState: State = {
  emoji: Constants.EmojiDropdownOptions[0],
  error: '',
  format: Constants.FormatTypeOptions[0],
  isGenerating: false,
  language: Constants.LanguageDropdownOptions[0],
  length: Constants.LengthDropdownOptions[0],
  showHtmlModal: false,
  title: '',
  tone: Constants.ToneDropdownOptions[0],
  url: '',
  versionDisplay: 0,
  versionGenerate: 0,
  versions: [{...EMPTY_EMAIL}]
}

export class GenerateEmailComponent extends React.Component<Props, State> {

  public static contextType = RasaContext

  private responseRef

  constructor(props: Props) {
    super(props)
    this.state = baseEmptyState
    this.responseRef = React.createRef()
  }

  protected renderSelector = (selector: Types.SelectorType, className: string) => {
    if ( selector ) {
      return <div className={className}>
          <h5>{selector.title}</h5>
          <DropdownComponent data={selector.options}
                             selected={this.state[selector.key].key}
                             onChange={(e) => this.setSelectorType(e, selector)}/>
      </div>
    } else {
      return <div className={className}/>
    }
  }

  public render () {

    return <div className="ai-generate body-container generated-text-modal-body">
      <div className="left-side-container">
        {(this.props.selectors || []).map((selector: Types.SelectorType) => {
          return <div className="first-row-container flex-container">
            {this.renderSelector(selector, 'left-side-container')}
          </div>
        })}
      </div>
      <div className="right-side-container full-height">
        <div className="first-row-container">
          <h5>{this.responsesTitle()}</h5>
          <Nav tabs>
            { this.state.versions.map((message, index) => (
            <NavItem key={index}>
              <NavLink className={this.state.versionDisplay === index ? 'active' : ''}
                       onClick={() => this.setVersion(index)}>
                <span>
                  {(this.state.isGenerating && this.state.versionGenerate === index) ? '* ' : ''}Version {index + 1}
                </span>
              </NavLink>
            </NavItem>
            ))}
          </Nav>
          <TabContent>
            <TabPane>
              <Input className="response-text" type="textarea" id="response-text"
                     ref={this.responseRef}
                     value={this.state.versions[this.state.versionDisplay].textBody}
                     onFocus={(e) => e.target.select()}
                     onChange={this.setTextMessage}/>
            </TabPane>
            <div className="actions flex-centered">
                <Button disabled={!this.hasCurrentVersion()} onClick={this.copyCurrentText}>
                  <span>Copy</span>
                </Button>
                <Button disabled={!this.hasCurrentVersion() && this.props.callback !== null} onClick={this.save}>
                  <span>Save</span>
                </Button>
                <Button disabled={!this.hasCurrentHtml()} onClick={this.showHtml}>
                  <span>HTML</span>
                </Button>
                <Button disabled={this.state.isGenerating} onClick={this.generateEmail}>
                  <span>Generate</span>
                </Button>
                <Button disabled={!this.hasCurrentVersion()} onClick={this.newVersion}>
                  <span>New</span>
                </Button>
            </div>
          </TabContent>
        </div>
        { this.state.isGenerating && <div className="second-row-container">
          <Loading size="32"></Loading>
        </div>}
        { this.state.error && <div className="second-row-container">
          <span className="error">{this.state.error}</span>
        </div>}
        { this.htmlModal()}
      </div>
    </div>
  }

  protected responsesTitle = (): string => this.props.responsesTitle || 'Responses'

  protected showHtml = () => {
    this.setState({
      showHtmlModal: true,
    })
  }

  protected htmlModal = () => {
    const version: Types.Email = this.state.versions[this.state.versionDisplay]
    return <div className="generate-email-modal">
        <Modal isOpen={this.state.showHtmlModal} className="generate-email-modal">
          <ModalBody>
            {version.subject.length > 0 && <h3>{version.subject}</h3>}
            <div className="email-content">
              <div dangerouslySetInnerHTML={{__html: this.state.versions[this.state.versionDisplay].htmlBody}}/>
            </div>
            {(version.notes || '').length > 0 && <div className="email-notes">{version.notes}</div>}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.setState({ showHtmlModal: false })}>
              Close
            </Button>
            <Button onClick={() => this.copyCurrentHtml()}>
              Copy
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  }

  protected hasCurrentHtml(): boolean {
    return !this.state.isGenerating && this.state.versions[this.state.versionDisplay].htmlBody.length > 0
  }

  // Modal overrides
  protected saveDisabled(data: any): boolean {
    return !this.hasCurrentVersion()
  }

  protected secondActionDisabled(data: any): boolean {
    return !this.hasCurrentVersion()
  }

  protected buildChatPayload(promptType: string): ChatPayload {
    return {
      aiPromptType: this.props.aiPromptType,
      content: '',
      emojis: this.state.emoji.value,
      includeBackground: true,
      language: this.state.language.value,
      length: this.state.length.value,
      prompt: this.props.prompts,
      promptType,
      topics: [],
      title: this.state.title,
      tone: this.state.tone.value,
      url: '',
    }
  }

  private setHTMLMessage = (rexResponse: string) => {
    const parts = rexResponse.split('```')
    if ( parts.length > 1 ) {
      const html: string = parts[1].replace(/^html/, '').trim()
      this.updateEmailBody("htmlBody", html, false)
      this.updateEmailBody("subject", parts[0], false)
      if ( parts.length > 2 ) {
        this.updateEmailBody("notes", parts[2], false )
      }
    } else {
      this.updateEmailBody("htmlBody", rexResponse, false)
    }
  }

  private setTextMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.updateEmailBody("textBody", event.target.value, false)
  }

  private updateEmailBody = (field: string, text: string, append: boolean) => {
    this.setState({
      versions: this.state.versions.map((message, index) => {
        if ( index === this.state.versionDisplay ) {
          return {
            ...message,
            [field]: append ? message[field] + text : text
          }
        } else {
          return message
        }
      }),
    })
  }

  private setVersion = (version: number) => {
    this.setState({
      versionDisplay: version,
    })
  }

  private setSelectorType = (event: OnChangeEvent, selector: Types.SelectorType) => {
    this.setState({
      ...this.state,
      [selector.key]: event.selected,
    })
  }

  private copyCurrentText = (): void => {
    copyToClipboard(this.getCurrentText())

    // YUCK.  In order to select the text, I need the DOM element.  But react REF
    // only gives me a React element.  focus doesnt' invoke the onFocus method automatically.
    // So this hack - call the react focus, then get the DOM element and pass it to the onFocus
    // method directly.
    this.responseRef.current.focus()
    this.responseRef.current.props.onFocus({target: document.getElementById('response-text')})
  }

  private copyCurrentHtml = (): void => {
    copyToClipboard(this.state.versions[this.state.versionDisplay].htmlBody)
  }

  private updateTextEmail: Data.ChatCallback = (response: ChatResponse) => {
    if ( !response ) {
      this.setState({
        isGenerating: false,
      })
    } else {
      if ( response.done ) {
        this.setState({
          isGenerating: false,
        })
      } else if ( response.error ) {
        this.setState({
          isGenerating: false,
          error: response.error,
        })
      }
      if ( response.message ) {
        this.updateEmailBody("textBody", response.message, true)
      }
    }
  }

  private getCurrentText = (): string => {
    return this.state.versions[this.state.versionDisplay].textBody
  }

  private hasCurrentVersion(): boolean {
    return !this.state.isGenerating && this.getCurrentText().length > 0
  }

  private newVersion = (): void => {
    // For now, overriding save to mean "open a new tab"
    this.setState({
      versions: this.state.versions.concat([{...EMPTY_EMAIL}]),
      versionDisplay: this.state.versions.length,
      versionGenerate: this.state.versions.length,
    })
  }

  private generateEmail = () => {
    this.setState({
      isGenerating: true,
      error: '',
      versionGenerate: this.state.versionDisplay,
    }, () => {
      Promise.all([
        this.generateTextEmail(),
        this.generateHTMLEmail(),
      ])
    })
  }

  private save = () => {
    if ( this.props.callback ) {
      this.props.callback(this.state.versions[this.state.versionDisplay])
    }
  }

  private generateTextEmail = () => {
    Data.streamRexResponse(
      this.props.communityIdentifier,
      this.buildChatPayload("text"),
      GenerationType.TEXT,
      this.updateTextEmail
    )
  }

  private generateHTMLEmail = () => {
    Data.getRexResponse(
      this.props.communityIdentifier,
      this.buildChatPayload("HTML"),
    ).then((response: ChatResponse) => {
      this.setHTMLMessage(response.message)
    })
  }

}
