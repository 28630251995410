import { ActiveCampaignIcon } from 'components/icons/activeCampaign'
import { ConstantContactIcon } from 'components/icons/constantContact'
import { DeskeraIcon } from 'components/icons/deskera'
import { FontevaIcon } from 'components/icons/fonteva'
import { HigherLogicIcon } from 'components/icons/higherLogic'
import { HubspotIcon } from 'components/icons/hubspot'
import { ImisIcon } from 'components/icons/imis'
import { ImpexiumIcon } from 'components/icons/impexium'
import { InfusionsoftIcon } from 'components/icons/infusionsoft'
import { InstagramIcon } from 'components/icons/instagram'
import { IntegratelyIcon } from 'components/icons/integrately'
import { KlaviyoIcon } from 'components/icons/klaviyo'
import { LinkedInIcon } from 'components/icons/linkedin'
import { MailchimpIcon } from 'components/icons/mailchimp'
import { NimbleIcon } from 'components/icons/nimble'
import { PabblyIcon } from 'components/icons/pabbly'
import { PipedreamIcon } from 'components/icons/pipedream'
import { PocketLogo } from 'components/icons/pocket'
import { ReplugIcon } from 'components/icons/replug'
import { SalesflareIcon } from 'components/icons/salesflare'
import { SalesforceIcon } from 'components/icons/salesforce'
import { SendFoxIcon } from 'components/icons/sendfox'
import { ShopifyIcon } from 'components/icons/shopify'
import { StripeIcon } from 'components/icons/stripe'
import { SyncSpiderIcon } from 'components/icons/syncSpider'
import { WordPressIcon } from 'components/icons/wordpress'
import { YourMembershipIcon } from 'components/icons/your-membership'
import { ZapierIcon } from 'components/icons/zapier'
import * as GenericRedux from 'generic/genericRedux'
import { isDevelopment } from 'generic/utility'
import { isEmpty } from 'lodash'
import React from 'react'
import {
  CommunitySystem,
  IntegrationSystems,
} from 'shared/data-layer/integration'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import { IntegrationErrors } from 'shared_server_client/types/integrations'
import { UsageStats } from 'shared_server_client/types/usage_stats'
import * as GlobalConstants from '../../../constants'
import * as Constants from '../constants'
import { ZoomIcon } from 'components/icons/zoom'
import { RasaLogo } from '../../icons/rasalogo';
import { GrowthZoneIcon } from 'components/icons/growthZone'

let deskeraOAuthBaseUrl = 'https://oauth.deskera.com'
if (isDevelopment()) {
  deskeraOAuthBaseUrl = 'https://oauth-staging.deskera.xyz'
}

export enum IntegrationStatus {
  available,
  comingSoon,
  notYet,
}

export interface Filter {
  description: string
  id: string
  label?: string
  name: string
  type?: string
  value?: string
}

export interface SystemInformation {
  attributeUrl?: string
  authenticationText?: string
  connectionText?: string
  fieldMappings?: any
  filters?: Filter[]
  keyUrl?: string
  name: string
  selectIcon?: any
  status: IntegrationStatus
  syncNewRasaContacts?: boolean
  syncNewRasaContactsText?: string
  syncText?: string
  targetObject?: string
  twoWayStatus?: IntegrationStatus
  twoWayFieldNeeded?: boolean
  twoWayFieldNeededText?: string
}

const DeskeraSystem: SystemInformation = {
  authenticationText: `The Deskera CRM integration is a one-way sync.
    All of your contacts will sync unless you create a custom field (field type: Number) named "RasaNewsletter__c" and a value of 0 (exclude) or 1 (include) is given.`,
  fieldMappings: {
    email: 'email',
    external_id: 'id',
    first_name: 'first_name',
    is_subscribed: 'OptedOutRasa__c',
    last_name: 'last_name',
    updated: 'updated_at',
  },
  filters: [
    {
      description: 'contacts who are',
      id: 'filter_id',
      name: 'filter_name',
      type: 'filter',
    },
  ],
  keyUrl: `${deskeraOAuthBaseUrl}/?response_type=code&state=`,
  name: IntegrationSystems.Deskera,
  selectIcon: <DeskeraIcon className="r-deskera" />,
  status: IntegrationStatus.available,
  syncText: 'Sync All contacts or only the RasaNewsletter__c contacts ',
  targetObject: 'contact',
  twoWayStatus: IntegrationStatus.notYet,
  twoWayFieldNeeded: true,
  twoWayFieldNeededText: '(requires an \'OptedOutRasa__c\' checkbox field to be created in Deskera)',
}

const SalesforceSystem: SystemInformation = {
  authenticationText: `The Salesforce integration is a two-way sync but requires an ‘OptedOutRasa__c’ custom field for the two way sync.
    All of your Salesforce contacts will sync UNLESS you create a custom (boolean) field named "RasaNewsletter__c" in Salesforce and check its box on each contact you want to sync with your rasa.io account.
    The sync can be one-way if desired.`,
  fieldMappings: {
    email: 'Email',
    external_id: 'Id',
    first_name: 'FirstName',
    is_subscribed: 'OptedOutRasa__c',
    last_name: 'LastName',
    updated: 'LastModifiedDate',
  },
  filters: [
    {
      description: 'contacts who are',
      id: 'filter_id',
      name: 'filter_name',
      type: 'filter',
    },
  ],
  keyUrl:
    `https://${isDevelopment() ? 'test' : 'login'}.salesforce.com/services/oauth2/authorize?response_type=code&state=`,
  name: IntegrationSystems.Salesforce,
  selectIcon: <SalesforceIcon className="r-salesforce" />,
  status: IntegrationStatus.available,
  syncNewRasaContacts: false,
  syncNewRasaContactsText: 'Synchronize new customers?',
  syncText: 'Sync All contacts or only the custom filtered contacts ',
  targetObject: 'contact',
  twoWayStatus: IntegrationStatus.available,
  twoWayFieldNeeded: true,
  twoWayFieldNeededText: '(requires above package to be installed in Salesforce)',
}

const MailchimpSystem: SystemInformation = {
  authenticationText: `The Mailchimp integration is a two-way sync but you can only sync 1 Audience from Mailchimp.
    If you would like to combine Audiences create a custom Audience in Mailchimp then sync that Audience to your rasa.io account.
    The sync can be one-way if desired.`,
  fieldMappings: {
    email: 'email_address',
    first_name: 'FNAME',
    is_subscribed: 'status',
    last_name: 'LNAME',
  },
  filters: [
    {
      description: 'Audience',
      id: 'audience_id',
      name: 'audience_name',
      type: 'audience',
    },
  ],
  keyUrl:
    'https://login.mailchimp.com/oauth2/authorize?response_type=code&state=',
  name: IntegrationSystems.Mailchimp,
  selectIcon: <MailchimpIcon className="r-mailchimp" />,
  status: IntegrationStatus.available,
  syncText: 'Select the Audience that you would like to sync',
  targetObject: 'contact',
  twoWayStatus: IntegrationStatus.available,
}

const HubspotSystem: SystemInformation = {
  authenticationText: `The Hubspot integration is a two-way sync. You can only sync 1 set of contacts from Hubspot to your rasa.io account.\n
    To do so, those contacts must to be part of the same list or subscription type. To sync all contacts, create an all contact list in Hubspot.
    The sync can be one-way if desired. <a href='${GlobalConstants.RASA_HELP_HUBSPOT_DOCUMENTATION}' target='_blank'>
    Click here to read more about the HubSpot integration.</a>`,
  fieldMappings: {
    email: 'email',
    first_name: 'firstname',
    is_subscribed: 'rasa_subscribed',
    last_name: 'lastname',
    segment_code: 'segment_code'
  },
  filters: [
    {
      description: 'List',
      id: 'list_id',
      name: 'list_name',
      type: 'list',
    },
    {
      description: 'Subscription Type',
      id: 'subscription_type_id',
      name: 'subscription_type_name',
      type: 'subscription_type',
    },
  ],
  keyUrl:
    'https://app.hubspot.com/oauth/authorize',
  name: IntegrationSystems.Hubspot,
  selectIcon: <HubspotIcon className="r-hubspot" />,
  status: IntegrationStatus.available,
  syncText: 'Select the list you will synchronize with',
  targetObject: 'contact',
  twoWayStatus: IntegrationStatus.available,
}

const PabblySystem: SystemInformation = {
  name: IntegrationSystems.Pabbly,
  selectIcon: <PabblyIcon className="r-pabbly" />,
  status: IntegrationStatus.available,
}

const PipedreamSystem: SystemInformation = {
  name: IntegrationSystems.Pipedream,
  selectIcon: <PipedreamIcon className="r-pipedream" />,
  status: IntegrationStatus.available,
}

const SyncSpiderSystem: SystemInformation = {
  name: IntegrationSystems.SyncSpider,
  selectIcon: <SyncSpiderIcon className="r-syncspider" />,
  status: IntegrationStatus.available,
}

const ZapierSystem: SystemInformation = {
  name: IntegrationSystems.Zapier,
  selectIcon: <ZapierIcon className="r-zapier" />,
  status: IntegrationStatus.available,
}

const CustomIntegrationApiKeySystem: SystemInformation = {
  name: IntegrationSystems.API,
  selectIcon: <RasaLogo />,
  status: IntegrationStatus.available,
}

const IntegratelySystem: SystemInformation = {
  name: IntegrationSystems.Integrately,
  selectIcon: <IntegratelyIcon className="r-integrately" />,
  status: IntegrationStatus.available,
}

const ConstantContactSystem: SystemInformation = {
  authenticationText: 'The Constant Contact integration is a two-way sync. You can only sync 1 set of contacts from Constant Contact to your rasa.io account. To do so, those contacts need to be part of the same List. To sync all contacts, create an all-contact List in Constant Contact. The sync can be one-way if desired.',
  fieldMappings: {
    email: 'email',
    first_name: 'firstname',
    last_name: 'lastname',
  },
  filters: [
    {
      description: 'List',
      id: 'list_id',
      name: 'list_name',
      type: 'list',
    },
  ],
  keyUrl:
    'https://authz.constantcontact.com/oauth2/default/v1/authorize',
  name: IntegrationSystems.ConstantContact,
  selectIcon: <ConstantContactIcon className="r-constantcontact" />,
  status: IntegrationStatus.available,
  syncText: 'Select a List or a Subscription Type that you would like to sync',
  targetObject: 'contact',
  twoWayStatus: IntegrationStatus.available,
}

const ShopifySystem: SystemInformation = {
  authenticationText: `The Shopify integration is a one-way sync.
    You can only sync customers who have chosen to receive marketing emails.`,
  fieldMappings: {
    email: 'email',
    first_name: 'first_name',
    last_name: 'last_name',
  },
  filters: [
    {
      description: 'all customers who',
      id: 'filter_id',
      name: 'filter_name',
      type: 'filter',
    },
  ],
  keyUrl: '.myshopify.com/admin/oauth/authorize?scope=read_customers&state=',
  name: IntegrationSystems.Shopify,
  selectIcon: <ShopifyIcon className="r-shopify" />,
  status: IntegrationStatus.available,
  syncText: 'Select the type of customers you would like to sync',
  targetObject: 'customer',
  twoWayStatus: IntegrationStatus.notYet,
}

const InfusionsoftSystem: SystemInformation = {
  authenticationText: `The Infusionsoft integration is a one-way sync.
    You can only sync customers who have chosen to receive marketing emails.`,
  fieldMappings: {
    email: 'email',
    first_name: 'given_name',
    is_subscribed: 'email_opted_in',
    last_name: 'family_name',
  },
  filters: [
    {
      description: 'contacts tagged with',
      id: 'filter_id',
      name: 'filter_name',
      type: 'filter',
    },
  ],
  keyUrl:
    'https://signin.infusionsoft.com/app/oauth/authorize?response_type=code&state=',
  name: IntegrationSystems.Infusionsoft,
  selectIcon: <InfusionsoftIcon className="r-infusionsoft" />,
  status: IntegrationStatus.available,
  syncText: 'Select one or more Tags that you would like to sync',
  targetObject: 'contact',
  twoWayStatus: IntegrationStatus.notYet,
}

const ReplugSystem: SystemInformation = {
  authenticationText: 'The Replug integration is an URL Shortener Integration. <br/>Once connected, all new content will get displayed in your newsletter with a shortened URL.',
  fieldMappings: {},
  filters: [
    {
      description: 'Campaign',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: 'https://app.replug.io/oauth/authorize/?response_type=code&scope=*&state=',
  name: IntegrationSystems.Replug,
  selectIcon: <ReplugIcon className="r-replug" />,
  status: IntegrationStatus.available,
  syncText: 'Select the Campaign you would like to sync',
  targetObject: '',
  twoWayStatus: IntegrationStatus.notYet,
}

const SendFoxSystem: SystemInformation = {
  authenticationText: 'The SendFox integration is a one-way sync. You can only sync 1 List from SendFox to your rasa.io account.\
    To sync all contacts, create an all-contact List in SendFox.',
  fieldMappings: {
    email: 'email',
    external_id: 'id',
    first_name: 'first_name',
    last_name: 'last_name',
    updated: 'updated_at',
  },
  filters: [
    {
      description: 'List',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: 'https://sendfox.com/oauth/authorize?response_type=code&state=',
  name: IntegrationSystems.SendFox,
  selectIcon: <SendFoxIcon className="r-sendfox" />,
  status: IntegrationStatus.available,
  syncText: 'Select the List you would like to sync',
  targetObject: 'contact',
  twoWayStatus: IntegrationStatus.notYet,
}

const SalesflareSystem: SystemInformation = {
  authenticationText: 'The Salesflare integration is a one-way sync.',
  fieldMappings: {
    email: 'email',
    external_id: 'id',
    first_name: 'firstname',
    last_name: 'lastname',
    updated: 'modification_date',
  },
  filters: [
    {
      description: 'all contacts',
      id: 'filter_id',
      name: 'filter_name',
      type: 'filter',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.Salesflare,
  selectIcon: <SalesflareIcon className="r-salesflare" />,
  status: IntegrationStatus.available,
  syncText: '',
  targetObject: 'contact',
  twoWayStatus: IntegrationStatus.notYet,
}

const StripeSystem: SystemInformation = {
  authenticationText: 'The Stripe integration is a one-way sync.',
  fieldMappings: {
    email: 'email',
    external_id: 'id',
    first_name: 'name',
  },
  filters: [
    {
      description: 'all contacts',
      id: 'filter_id',
      name: 'filter_name',
      type: 'filter',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.Stripe,
  selectIcon: <StripeIcon className="r-stripe" />,
  status: IntegrationStatus.available,
  syncText: '',
  targetObject: 'contact',
  twoWayStatus: IntegrationStatus.notYet,
}

const HigherLogicSystem: SystemInformation = {
  authenticationText: 'The Higher Logic integration is a one-way sync.',
  fieldMappings: {},
  filters: [
    {
      description: 'all contacts',
      id: 'filter_id',
      name: 'filter_name',
      type: 'filter',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.HigherLogic,
  selectIcon: <HigherLogicIcon className="r-higherlogic" />,
  status: IntegrationStatus.available,
  syncText: '',
  targetObject: 'Contacts',
  twoWayStatus: IntegrationStatus.notYet,
}

const HigherLogicMagnetMailSystem: SystemInformation = {
  authenticationText: 'The Magnet Mail integration is a one-way sync.',
  fieldMappings: {},
  filters: [
    {
      description: 'Group',
      id: 'filter_id',
      name: 'filter_name',
      type: 'group',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.HigherLogicMagnetMail,
  selectIcon: <HigherLogicIcon className="r-higherlogic" />,
  status: IntegrationStatus.available,
  syncText: 'Select a Group that you would like to sync',
  targetObject: 'Contacts',
  twoWayStatus: IntegrationStatus.notYet,
}

const ActiveCampaignSystem: SystemInformation = {
  authenticationText: 'The Active Campaign integration is a two-way sync. It can also sync events.',
  fieldMappings: {},
  filters: [
    {
      description: 'List',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.ActiveCampaign,
  selectIcon: <ActiveCampaignIcon className="r-activecampaign" />,
  status: IntegrationStatus.available,
  syncText: 'Select a List that you would like to sync',
  targetObject: 'Contacts',
  twoWayStatus: IntegrationStatus.available,
}

const InstagramSystem: SystemInformation = {
  authenticationText: 'The Instagram integration is a content integration.',
  fieldMappings: {},
  filters: [
    {
      description: 'List',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: 'https://api.instagram.com/oauth/authorize?response_type=code&scope=user_profile,user_media&state=',
  name: IntegrationSystems.Instagram,
  selectIcon: <InstagramIcon className="system-icon r-instagram" />,
  status: IntegrationStatus.available,
  syncText: '',
  targetObject: 'content',
  twoWayStatus: IntegrationStatus.notYet,
}

const LinkedInSystem: SystemInformation = {
  authenticationText: 'The LinkedIn integration is a CONTENT integration. <br/> This integration allows you to pull in content from your Company Profile (not applicable to personal accounts).',
  fieldMappings: {},
  filters: [
    {
      description: 'List',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&scope=r_emailaddress,r_liteprofile,r_organization_social,rw_organization_admin&state=',
  name: IntegrationSystems.LinkedIn,
  selectIcon: <LinkedInIcon className="system-icon r-linkedin" />,
  status: IntegrationStatus.available,
  syncText: '',
  targetObject: 'content',
  twoWayStatus: IntegrationStatus.notYet,
}

const ZoomSystem: SystemInformation = {
  authenticationText: 'The Zoom integration is a CONTENT integration. <br/> This integration allows you to pull in webinars from your account.',
  fieldMappings: {},
  filters: [
    {
      description: 'List',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: 'https://zoom.us/oauth/authorize?response_type=code&state=',
  name: IntegrationSystems.Zoom,
  selectIcon: <ZoomIcon className="system-icon r-zoom" />,
  status: IntegrationStatus.available,
  syncText: '',
  targetObject: 'content',
  twoWayStatus: IntegrationStatus.notYet,
}

const PocketRssSystem: SystemInformation = {
  authenticationText: 'The Pocket RSS integration is a CONTENT integration. <br/> This integration allows you to pull in content from your User Profile (not applicable to other accounts).',
  fieldMappings: {},
  filters: [
    {
      description: 'List',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: 'https://getpocket.com/auth/authorize',
  name: IntegrationSystems.PocketRss,
  selectIcon: <PocketLogo svgheight="80" svgwidth="80" className="system-icon" />,
  status: IntegrationStatus.available,
  syncText: '',
  targetObject: 'content',
  twoWayStatus: IntegrationStatus.notYet,
}

const WordPressSystem: SystemInformation = {
  authenticationText: 'This integration is a one-way sync, creating a Contact for each WordPress user.  This integration does not synchronize with WordPress form submissions to sync those kinds of entries, you will need to use a different integrator (such as Zapier).  <br/> This integration requires use of a WordPress generated Application Password.',
  fieldMappings: {},
  filters: [
    {
      description: 'Role',
      id: 'filter_id',
      name: 'filter_name',
      type: 'role',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.WordPress,
  selectIcon: <WordPressIcon className="r-wordpress" />,
  status: IntegrationStatus.available,
  syncText: 'Select one or more Roles that you would like to sync',
  targetObject: 'Contacts',
  twoWayStatus: IntegrationStatus.notYet,
}

const KlaviyoSystem: SystemInformation = {
  authenticationText: `The Klaviyo integration is a two-way sync. You can only sync 1 set of contacts from Klaviyo to your rasa.io account.\
    To do so, those contacts need to be part of the same List.
    To sync all contacts, create an all-contact List in Klaviyo.
    The sync can be one-way if desired.`,
  fieldMappings: {},
  filters: [
    {
      description: 'List',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.Klaviyo,
  selectIcon: <KlaviyoIcon className="r-klaviyo" />,
  status: IntegrationStatus.available,
  syncText: 'Select a List that you would like to sync',
  targetObject: 'Contacts',
  twoWayStatus: IntegrationStatus.available,
}

const YourMembershipSystem: SystemInformation = {
  authenticationText: '',
  fieldMappings: {},
  filters: [
    {
      description: 'Campaign',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.YourMembership,
  selectIcon: <YourMembershipIcon className="r-yourmembership" />,
  status: IntegrationStatus.available,
  syncText: 'Select the Campaign you would like to sync',
  targetObject: '',
  twoWayStatus: IntegrationStatus.notYet,
}

const ImpexiumSystem: SystemInformation = {
  authenticationText: 'The Impexium integration is a one-way sync',
  fieldMappings: {},
  filters: [
    {
      description: 'All Contacts',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.Impexium,
  selectIcon: <ImpexiumIcon svgwidth="100" svgheight="50" />,
  status: IntegrationStatus.available,
  syncText: 'Select the Campaign you would like to sync',
  targetObject: '',
  twoWayStatus: IntegrationStatus.notYet,
}

const InformzSystem: SystemInformation = {
  authenticationText: 'The Informz integration is a one-way sync',
  fieldMappings: {
    email: 'email',
    external_id: 'subscriber_id',
    first_name: 'first_name',
    is_subscribed: 'is_unsubscriber',
    last_name: 'last_name',
    updated: 'modified_date',
  },
  filters: [
    {
      description: 'All Contacts',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.Informz,
  selectIcon: <HigherLogicIcon svgwidth="100" svgheight="50" />,
  status: IntegrationStatus.available,
  syncText: 'Select the Campaign you would like to sync',
  targetObject: '',
  twoWayStatus: IntegrationStatus.available,
}

const NimbleSystem: SystemInformation = {
  authenticationText: '',
  fieldMappings: {},
  filters: [
    {
      description: 'Campaign',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.Nimble,
  selectIcon: <NimbleIcon className="r-nimble" />,
  status: IntegrationStatus.available,
  syncText: 'Select the Campaign you would like to sync',
  targetObject: '',
  twoWayStatus: IntegrationStatus.notYet,
}

const ImisSystem: SystemInformation = {
  authenticationText: 'The iMIS integration is a one-way sync.',
  fieldMappings: {
    email: 'Email',
    external_id: 'ID',
    first_name: 'FirstName',
    last_name: 'LastName',
    updated: 'LastUpdated',
  },
  filters: [
    {
      description: 'all contacts',
      id: 'filter_id',
      name: 'filter_name',
      type: 'filter',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.Imis,
  selectIcon: <ImisIcon className="r-imis" />,
  status: IntegrationStatus.available,
  syncText: '',
  targetObject: 'contact',
  twoWayStatus: IntegrationStatus.notYet,
}

const FontevaSystem: SystemInformation = {
  authenticationText: '',
  fieldMappings: {},
  filters: [
    {
      description: 'Campaign',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.Fonteva,
  selectIcon: <FontevaIcon className="r-fonteva" />,
  status: IntegrationStatus.comingSoon,
  syncText: 'Select the Campaign you would like to sync',
  targetObject: '',
  twoWayStatus: IntegrationStatus.notYet,
}

const SalesforceMarketingCloud: SystemInformation = {
  authenticationText: `The Salesforce Marketting Cloud integration is a one-way sync`,
  fieldMappings: {},
  filters: [
    {
      description: 'All Contacts',
      id: 'filter_id',
      name: 'filter_name',
      type: 'list',
    },
  ],
  keyUrl: '',
  name: IntegrationSystems.SalesforceMarketingCloud,
  selectIcon: <SalesforceIcon className="r-salesforce" />,
  status: IntegrationStatus.available,
  syncText: 'Select the Data Extension / Audience that you would like to sync',
  targetObject: '',
  twoWayStatus: IntegrationStatus.notYet,
}

const GrowthZoneSystem: SystemInformation = {
  authenticationText: `The GrowthZone integration is a two-way sync. You can only sync 1 set of contacts from GrowthZone to your rasa.io account.\n
    The sync can be one-way if desired. <a href='${GlobalConstants.RASA_HELP_INTEGRATION_WORK}' target='_blank'>
    Click here to read more about the GrowthZone integration.</a>`,
  fieldMappings: {
    email: 'Email',
    external_id: 'ContactId',
    first_name: 'FirstName',
    last_name: 'LastName',
    is_subscribed: 'DoNotContact',
    updated: 'ActivityDate',
  },
  filters: [
    {
      description: 'all contacts',
      id: 'filter_id',
      name: 'filter_name',
      type: 'filter',
    },
  ],
  keyUrl:
    'https://claraassociation.growthzoneapp.com/oauth/authorize',
  name: IntegrationSystems.GrowthZone,
  selectIcon: <GrowthZoneIcon className="r-growthzone" />,
  status: IntegrationStatus.available,
  syncText: 'Select the list you will synchronize with',
  targetObject: 'contact',
  twoWayStatus: IntegrationStatus.available,
}

const allSystems = [
  SalesforceSystem,
  MailchimpSystem,
  HubspotSystem,
  ZapierSystem,
  ActiveCampaignSystem,
  DeskeraSystem,
  GrowthZoneSystem,
  HigherLogicSystem,
  HigherLogicMagnetMailSystem,
  InfusionsoftSystem,
  InstagramSystem,
  KlaviyoSystem,
  LinkedInSystem,
  PocketRssSystem,
  PabblySystem,
  PipedreamSystem,
  ReplugSystem,
  SalesflareSystem,
  SendFoxSystem,
  ShopifySystem,
  StripeSystem,
  SyncSpiderSystem,
  WordPressSystem,
  ImisSystem,
  IntegratelySystem,
  ConstantContactSystem,
  YourMembershipSystem,
  NimbleSystem,
  FontevaSystem,
  SalesforceMarketingCloud,
  ImpexiumSystem,
  InformzSystem,
  ZoomSystem,
  CustomIntegrationApiKeySystem,
]

export const systemInformation = (systemName: string): SystemInformation => {
  return (
    allSystems.find((s: SystemInformation) => s.name === systemName) || {
      name: systemName,
      status: IntegrationStatus.notYet,
    }
  )
}

export interface FieldMapping {
  id: number
  rasa_field_name: string
  target_field_name: string
  update_in_rasa: number
  update_in_target: number
}

export interface ConfiguredCommunitySystem extends CommunitySystem {
  key: string
  accountId?: string
  api_endpoint?: string
  clientId?: string
  clientSecret?: string
  creator_email: string
  dataExtensionKey?: string
  emailFieldLabel?: string,
  fieldMappings: FieldMapping[]
  loadSegmentField?: boolean
  loadEmailField?: boolean
  password?: string
  query_name?: string
  rasa_api_attribute: string
  secret: string
  selectedFilterId?: string
  selectedFilterName?: string
  selectedFolderName?: any
  selectedFolderId?: any
  selectedDomainName?: any
  selectedDomainId?: any
  storeName?: string
  subDomain?: string
  system_id: number
  systemAccountName: string
  systemAppId?: string
  systemApiKey: string
  systemAppName?: string
  systemAppKey?: string
  systemAppPassword?: string
  systemAppUserEmail?: string
  systemAppUserPassword?: string
  systemMetadata: any
  systemName: string
  system_api_attribute: string
  token: string
  username?: string
  filter_type?: string
  filter_id?: string
  filter_name?: string
  list_name?: string
  list_id?: string
  subscription_type_id?: string
  subscription_type_name?: string
  segmentFieldLabel?: string,
}

export const emptyCommunitySystem: ConfiguredCommunitySystem = {
  accountId: '',
  available: null,
  clientId: '',
  clientSecret: '',
  community_identifier: '',
  community_system_id: null,
  connected: null,
  creator_email: '',
  dataExtensionKey: '',
  display_name: '',
  fieldMappings: [],
  id: null,
  integration_types: '',
  is_active: null,
  key: '',
  name: '',
  rasa_api_attribute: '',
  secret: '',
  selectedFilterId: '',
  selectedFilterName: '',
  selectedFolderName: '' ,
  selectedFolderId: '',
  selectedDomainName: '',
  selectedDomainId: '',
  status: '',
  storeName: '',
  subDomain: '',
  systemAccountName: '',
  systemApiKey: '',
  systemAppId: '',
  systemAppKey: '',
  systemAppName: '',
  systemAppPassword: '',
  systemAppUserEmail: '',
  systemAppUserPassword: '',
  systemMetadata: {},
  systemName: '',
  system_api_attribute: '',
  system_id: null,
  token: '',
}

export const zapierConnectInstructions = [
  'Click the button above to generate your rasa.io API key',
  'Copy your key',
  <div>
    <a href="https://zapier.com/app/zaps" target="_blank" rel="noopener">
      Login to your Zapier account
    </a>
  </div>,
  'Create new Zap using your rasa.io credentials and API Key',
]

export const zapierAlreadyConnectedInstructions = [
  'Copy your key',
  <div>
    <a href="https://zapier.com/app/zaps" target="_blank" rel="noopener">
      Login to your Zapier account
    </a>
  </div>,
  'Create new Zap using your rasa.io credentials and API Key',
]

export const baseDropDownOptions: string[] = [
  Constants.SELECT_AN_AUDIENCE,
  Constants.SELECT_A_SUBSCRIPTION_TYPE,
  Constants.SELECT_AN_OPTION,
  Constants.SELECT_A_LIST_TYPE,
]

export const getFilterInfo = (data): Filter => {
  if (!isEmpty(data)) {
    const { selectedFilterType, systemName, system_api_attribute } = data
    if (system_api_attribute) {
      if (systemName === IntegrationSystems.Hubspot) {
        const type = selectedFilterType
          ? selectedFilterType
          : JSON.parse(system_api_attribute).filter_type
        const response = systemInformation(systemName).filters.filter(
          (f) => f.type === type,
        )
        return response.length
          ? response[0]
          : systemInformation(systemName).filters[0]
      } else {
        return systemInformation(systemName).filters[0]
      }
    }
  }
  return { description: '', id: '', name: '' }
}

export const notificationMessages = {
  CreateFailure: 'Something went wrong, try again or contact us',
  CreateSuccess: 'Connected!',
  DisconnectSuccess: 'Disconnected!',
  ReconnectSuccess: 'Reconnected!',
  SyncNowFailure: 'Something went wrong, try again or contact us',
  SyncNowSuccess: 'Now syncing, check back in a few minutes',
  UpdateFailure: 'Something went wrong, try again or contact us',
  UpdateSuccess: 'Your next sync will apply this change',
  [IntegrationErrors.INVALID_USER]:
    'Please check your authentication credentials',
  [IntegrationErrors.INVALID_PASSWORD]:
    'The password you provided is incorrect',
  [IntegrationErrors.INVALID_APPLICATION_PASSWORD]:
    'You need to provide an Application password, not a login credential',
}

export type SettingsIntegrationsConfigProps =
  GenericRedux.AllComponentPropsWithModal<ConfiguredCommunitySystem>

export interface SettingsIntegrationsConfigState {
  activeCommunity: any
  clientIds: object
  hasCognitoIdentifier: boolean
  hasFeatureAccess?: boolean
  isSettingsLoading: boolean
  params: any
  person: any
  plan: BillingPlan.BillingPlan
  usageStats: UsageStats
}
export const SubscriptionField = {
  subscription_type_name: 'subscription_type_name',
  subscription_type_id: 'subscription_type_id',
}

export const ListField = {
  selected_filter_name : 'selectedFilterName',
}
