import { Fade } from '@progress/kendo-react-animation'
import { Notification } from '@progress/kendo-react-notification'
import * as BannerActions from 'components/banner/actions'
import { BannerType } from 'components/banner/constants'
import { DropdownWithDividersComponent } from 'components/dropdown/component'
import * as Flash from 'components/flash'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { RasaBrowserComponent } from 'generic/rasaBrowserComponent'
import { isTruthy, isTruthyNumeric } from 'generic/utility'
import {isEmpty} from 'lodash'
import pluralize from 'pluralize'
import React from 'react'
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { sendConsent } from 'shared/data-layer/consent'
import { IntegrationSystems } from 'shared/data-layer/integration'
import * as Modals from 'shared/modals'
import { BillingPlanDetailCode, isOverContactLimit } from 'shared_server_client/types/billing_plan'
import * as Constants from '../../constants'
import '../../styles.css'
import * as Integrations from '../integrations'
import { Flag } from '../settingsIntegrations'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { Dataset } from 'generic/dataset'
import { FieldMapping } from '../../../common/field-mapping/component';

declare const RASA_SELF_SERVICE_API: string

interface ExistingFilter {
  id: string,
  name: string
  type: string,
}

export interface CustomField {
  name: string,
  value: string,
  type: string,
}

export interface IntegrationConfigRenderProps
extends Integrations.SettingsIntegrationsConfigProps, Integrations.SettingsIntegrationsConfigState {
  context: any,
  loadRecord: any,
  saveRecord: any,
}

export interface IntegrationConfigRenderState {
  [key: string]: any,
  addSuccess: boolean,
  addFailure: boolean,
  authenticated: boolean,
  confirmed: boolean,
  contactClientSuccess,
  contactsCount: number,
  dropdownAvailable: boolean,
  existingConnections: any[],
  existingFilter: ExistingFilter,
  filterSelected: boolean,
  integrationsCount: number,
  isConnecting: boolean,
  isGettingContactsCount: boolean,
  isKeyCreator: boolean,
  isLoading: boolean,
  hasRichIntegrationAccess: boolean,
  hasSegmentAccess: boolean,
  isSyncing: boolean,
  isUpdating: boolean,
  message: string,
  showCustomFilter: boolean,
  selectedExistingConnection: number,
  showExistingConnectionModal: boolean,
  customFields: CustomField[]
}

const integrationConfigRenderInitialState = {
  addSuccess: false,
  addFailure: false,
  authenticated: false,
  confirmed: false,
  contactsCount: 0,
  contactClientSuccess: '',
  dropdownAvailable: false,
  existingFilter: { id: '', name: '', type: '' },
  existingConnections: [],
  filterSelected: false,
  hasRichIntegrationAccess: false,
  hasSegmentAccess: false,
  integrationsCount: null,
  isConnecting: false,
  isGettingContactsCount: false,
  isKeyCreator: false,
  isLoading: true,
  isSyncing: false,
  isUpdating: false,
  message: '',
  showCustomFilter: false,
  selectedExistingConnection: null,
  showExistingConnectionModal: false,
  customFields: [],
}

export class IntegrationConfigRenderBase
extends RasaBrowserComponent<IntegrationConfigRenderProps, IntegrationConfigRenderState> {
  public static contextType = RasaContext
  protected communityId: string = null
  protected encodedCommunityIdentifier: string = null
  protected systemInfo: Integrations.SystemInformation = null
  protected systemApiAttribute = null
  protected systemMetadata = null
  protected filter: Integrations.Filter = null
  protected rasaApiKey: string = null
  protected userEmail: string = null

  protected supportsFilter: boolean = true
  protected showContactsCount = true
  protected showSyncNow = true
  protected sharedStore: SharedStore

  constructor(props: IntegrationConfigRenderProps) {
    super(props, integrationConfigRenderInitialState)
    this.communityId = this.props.activeCommunity.communityId
    this.encodedCommunityIdentifier = btoa(this.communityId)
    this.systemInfo = Integrations.systemInformation(this.props.data.systemName)
    this.userEmail = this.props.person.email
    this.showSyncNow = !isOverContactLimit(this.props.usageStats)
    this.confirmConnect = this.confirmConnect.bind(this)
  }

  public componentDidMount() {
    let authenticated = false
    let dropdownAvailable = false
    let existingFilter = { id: '', name: '', type: '' }
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([activeCommunity]) => {
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      this.setState({
        hasRichIntegrationAccess: avlFeatures.indexOf(BillingPlanDetailCode.RICH_INTEGRATION) > -1,
        hasSegmentAccess: avlFeatures.indexOf(BillingPlanDetailCode.SEGMENT_CODE) > -1
      })
    })
    if (this.filter && this.systemApiAttribute && this.systemApiAttribute.filter_name) {
      this.props.propertiesChanged({
        selectedFilterId: this.systemApiAttribute.filter_id,
        selectedFilterName: this.systemApiAttribute.filter_name,
        selectedFilterType: this.filter.type,
      })
      existingFilter = {
        id: this.systemApiAttribute.filter_id,
        name: this.systemApiAttribute.filter_name,
        type: this.filter.type,
      }
    }
    if (this.state.queries.operation) {
      if (this.state.queries.operation.includes('Failure')) {
        if (this.state.queries.message) {
          this.props.context.store.dispatch(Flash.showFlashError(this.state.queries.message))
        } else {
          this.props.context.store.dispatch(Flash.showFlashError(
            Integrations.notificationMessages[this.state.queries.operation]))
        }
      } else {
        authenticated = true
        if (!!this.props.data.is_active) {
          dropdownAvailable = false
        } else {
          dropdownAvailable = true
        }
      }
    }
    Promise.all([
      this.updateSyncStatus(),
      this.getFilters(),
      this.getCustomFields(),
      this.getExistingConnections(),
    ])
    .then(() => {
      this.initializeCustomProps()
      this.setState({ authenticated, dropdownAvailable, existingFilter }, () => {
        this.initializeCustomState()
          .then(() => {
            this.setState({ isLoading: false })
          })
      })
    })
  }

  public componentDidUpdate(prevProps: IntegrationConfigRenderProps) {
    if (prevProps.data.selectedFilterName !== this.props.data.selectedFilterName) {
      if (this.props.data.selectedFilterName !== Constants.ALL_CONTACTS) {
        this.componentUpdates()
        this.setState({ filterSelected: true })
      }
    }
  }

  public render() {
    // Note: we are changing props in some actions so we would need to re-evaluate while rendering
    this.systemApiAttribute = this.props.data.system_api_attribute
      ? JSON.parse(this.props.data.system_api_attribute) : null
    this.filter = Integrations.getFilterInfo(this.props.data)
    this.rasaApiKey = this.props.data.rasa_api_attribute ? JSON.parse(this.props.data.rasa_api_attribute).key : ''

    return <div>
      { this.state.isLoading
        ? <Loading size="32" />
        : <div>
          <div className="section-header-wrapper">
            <Fade enter={true} exit={true}>{this.renderNotification()}</Fade>
            <div className="section-header-label">INTEGRATIONS</div>
            <div className="section-header-text">{this.props.data.display_name}</div>
          </div>
          {this.renderHtml()}
        </div>
      }
    </div>
  }

  protected componentUpdates() {
    // do nothing
  }

  protected initializeCustomProps() {
    // do nothing
  }

  protected initializeCustomState(): Promise<void> {
    return Promise.resolve()
  }

  protected renderHtml() {
    return <div>
      <div className="settings-system-wrapper">
        <ReconnectIntegrationModal data={this.props.modals} closeModal={this.props.closeModal} title="WARNING" />
        <div className="settings-integrations-single-system-wrapper" key={this.props.data.id}>
          <div className="settings-integrations-single-system-logo">{this.systemInfo.selectIcon}</div>
          <div className="settings-integrations-single-system-details">
            <div className="settings-integrations-single-system-details-1">
              <div className="settings-integrations-single-system-name-details">
                {this.props.data.display_name} {this.isRichIntegration() ? '- Rich' : ''}
              </div>
              <Flag text="Connected"
                    status={!!this.props.data.is_active && (!this.isRichIntegration() || this.isRichIntegrationChecked())}/>
            </div>
          </div>
        </div>
      </div>
      {this.renderCustomBox()}
      {this.renderFilter()}
      {this.renderFieldMapping()}
      {this.renderContactCount()}
      <div className="clearfix" />
      {this.renderTwoWayStatus()}
      <div className="clearfix" />
      {this.renderSyncNewCustomers()}
      <div className="clearfix" />
      <div className="connect-disconnect-wrapper">
        {this.renderConnect()}
        {this.renderDisconnect()}
      </div>
      <div className="clearfix" />
      <div className="integration-tips">
        {this.renderIntegrationTips()}
      </div>
    </div>
  }

  protected renderIntegrationTips() {
    return null
  }

  protected renderCustomBox() {
    return null
  }

  protected renderFilterDropdown() {
    return <DropdownWithDividersComponent data={this.state.filters}
              selected={{name: this.props.data.selectedFilterName, value: this.props.data.selectedFilterId}}
              onChange={(e) => this.onDropdownChange(e)}
            />
  }

  protected renderFilter() {
    if (this.supportsFilter) {
      if (this.state.filters || this.props.data.selectedFilterName) {
        return <div className="filter-wrapper">
          <div className="filter-name-wrapper">
            <div>
              {this.state.dropdownAvailable && this.state.authenticated &&
                <div className="field-label-credentials">
                  {Integrations.systemInformation(this.props.data.systemName).syncText}
                </div>
              }
              <div className="filter-name-box">
                <div className="filter-name-text">
                  {(this.state.filters && this.state.dropdownAvailable)
                    ?
                    <div className="filters-dropdown">
                        {this.renderFilterDropdown()}
                        {this.renderCustomFilter()}
                      </div>
                    : <div>
                        {this.renderSelectedFilters()}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    }
  }

  protected renderFieldMapping() {
    const { data } = this.props;
    const { dropdownAvailable, queries } = this.state;

    if (!dropdownAvailable || !queries.operation.includes('Success')) {
      return null;
    }

    const fieldComponents = [];

    if (data.loadSegmentField && this.canSegmentation()) {
      fieldComponents.push(
        <FieldMapping
          key="segmentField"
          id={queries.id}
          label={data.segmentFieldLabel}
          onChanged={(e) => this.onSegmentDropdownChange(e)}
        />
      );
    }

    if (data.loadEmailField) {
      fieldComponents.push(
        <FieldMapping
          key="emailField"
          id={queries.id}
          label={data.emailFieldLabel}
          onChanged={(e) => this.onEmailDropdownChange(e)}
        />
      );
    }

    return fieldComponents.length > 0 ? <>{fieldComponents}</> : null;
  }

  protected renderSelectedFilters() {
    return this.props.data.is_active && this.props.data.selectedFilterName
      ? <div>
        {`You have successfully connected
        ${this.props.data.selectedFilterName !== Constants.ALL_ROLES
        ? ` ${this.formatedFilterDescription()}:`
        : ':'}
        `}
        <div className="selected-filter-name-wrapper"> {this.props.data.selectedFilterName}</div>
      </div>
      : null
  }

  protected getSelectedFilter() {
    return {name: this.props.data.selectedFilterName, value: this.props.data.selectedFilterId}
  }

  protected renderCustomFilter() {
    return null
  }

  protected renderContactCount() {
    return <div>
      {!!this.state.contactClientSuccess &&
      <div className="contact-client-success">
        {this.state.contactClientSuccess}
      </div>
      }
      <div className="clearfix" />
      <div className="contacts-count-wrapper">
        {this.state.isGettingContactsCount || this.state.isLoading ? <Loading size="32" /> : null}
      </div>
      <div>
        {(this.showContactsCount && this.state.authenticated &&
          this.state.contactsCount && !this.state.isGettingContactsCount && !this.props.data.is_active) ?
        <div className="contacts-count-wrapper">
        We found {this.state.contactsCount === 1000 ?
        `more than 1000 ${Integrations.systemInformation(this.props.data.systemName).targetObject}s`
        :
        pluralize(Integrations.systemInformation(this.props.data.systemName).targetObject,
        (this.state.contactsCount || 0), true)} to sync
        </div>
        : null}
      </div>
    </div>
  }

  protected renderSyncNewCustomers() {
    if (this.validateTwoWayStatus() && this.isTwoWayChecked() &&
      Integrations.systemInformation(this.props.data.systemName).syncNewRasaContacts) {
      return <div className="outbound-confirm-checkbox-wrapper left">
        <div className="outbound-confirm-checkbox-line1">
          <p className="outbound-confirm-checkbox-text">
            Push new rasa.io contacts to {this.props.data.systemName}
          </p>
          <input type="checkbox" className="outbound-confirm-checkbox"
            checked={this.isSyncNewRasaContacts()}
            onChange={(e) => this.onSyncNewContactsCheckboxChange(e.target.checked)}
          />
        </div>
        {Integrations.systemInformation(this.props.data.systemName).syncNewRasaContactsText && this.props.data.id &&
          <div className="outbound-confirm-checkbox-line2">
            <p className="outbound-confirm-checkbox-text">
              {Integrations.systemInformation(this.props.data.systemName).syncNewRasaContactsText}
            </p>
          </div>
        }
      </div>
    }
  }

  protected getRichIntegration() {
    return null
  }

  protected renderTwoWayStatus() {
    if (this.validateTwoWayStatus()) {
      return <div className="outbound-confirm-checkbox-wrapper">
         {this.getRichIntegration()}
        <div className="outbound-confirm-checkbox-line1">
          <p className="outbound-confirm-checkbox-text">
            Push rasa.io subscription and unsubscribe status to {this.props.data.systemName}
          </p>
          <input type="checkbox" className="outbound-confirm-checkbox"
            checked={this.isTwoWayChecked()}
            onChange={(e) => this.onTwoWayCheckboxChange(e.target.checked)}
          />
        </div>
        {Integrations.systemInformation(this.props.data.systemName).twoWayFieldNeeded && this.props.data.id &&
          <div className="outbound-confirm-checkbox-line2">
            <p className="outbound-confirm-checkbox-text">
              {this.renderTwoFieldNeededText()}
            </p>
          </div>
        }
      </div>
    }
  }

  protected renderTwoFieldNeededText() {
    return Integrations.systemInformation(this.props.data.systemName).twoWayFieldNeededText
  }

  protected renderConnect() {
    if (!this.props.data.is_active) {
      return <div className="connect-wrapper">
        {this.renderExistingConnectionModalJSX()}
        {!!this.validateToFinalize() && this.renderFinalizeHtml()}
        {!this.state.authenticated && !this.state.contactClientSuccess &&
          <div className="authenticate-wrapper">
            <div className="authenticate-title-and-text-wrapper">
              <h5 className="authenticate-title"> Quick Tip </h5>
              <div className="authenticate-text">
                <div dangerouslySetInnerHTML={this.renderQuickTip()}/>
              </div>
            </div>
            <div className={`authenticate-btn-wrapper-${this.isAuthenticateButtonEnabled() ? '' : 'disabled'}`}>
              <div className={`save-button connect ${this.isAuthenticateButtonEnabled()
                ? 'clickable-item save-button-enabled' : ''}
                authenticate-btn-${this.isAuthenticateButtonEnabled() ? '' : 'disabled'}`}
                onClick={() => this.connect()}> Authenticate
              </div>
            </div>
          </div>
        }
      </div>
    }
  }

  protected renderQuickTip() {
    return {
      __html : Integrations.systemInformation(this.props.data.systemName).authenticationText.split('<br/>').join('\n'),
    }
  }

  protected shouldRenderDisconnect() {
    return this.props.data.is_active
  }

  protected renderDisconnect() {
    if (this.shouldRenderDisconnect()) {
      return <div className="connect-disconnect-wrapper">
        <div className={'save-button clickable-item save-button-enabled disconnect'}
          onClick={(e) => this.disconnect()}>Disconnect
        </div>
        {this.showSyncNow && <div
          className={`save-button clickable-item syncnow ${this.state.isSyncing ? 'not-allowed' : 'save-button-enabled'}`}
          onClick={(e) => this.syncNow()}>Sync Now
        </div>
        }
      </div>
    }
  }

  protected renderNotification() {
    if (this.state.addSuccess) {
      return <Notification type={{ style: 'success', icon: false }} closable={false} onClose={() =>
        this.setState({ addSuccess: false, message: '' })
      }>
        <span>{Integrations.notificationMessages[this.state.message]}</span>
      </Notification>
    } else if (this.state.addFailure) {
      return <Notification type={{ style: 'error', icon: false }} closable={true} onClose={
        () => this.setState({ addFailure: false, message: '' })
      }>
        <span>{Integrations.notificationMessages[this.state.message] || this.state.message}</span>
      </Notification>
    } else {
      return null
    }
  }

  protected validateToFinalize(): boolean {
    return (this.state.authenticated && !this.supportsFilter) || (this.state.filterSelected &&
        this.state.authenticated && !Integrations.baseDropDownOptions.includes(this.props.data.selectedFilterName) &&
      !this.props.data.is_active && !this.state.isGettingContactsCount) ? true : false
  }

  protected renderFinalizeConfirmation() {
    return <Col className="confirm-connect">
      <p className="text">You are about to sync &nbsp;
        {(this.props.data.selectedFilterName && (this.props.data.selectedFilterName === Constants.ALL_CONTACTS
          || this.props.data.selectedFilterName === Constants.ALL_SUBSCRIPTIONS
          || this.props.data.selectedFilterName === Constants.ALL_ROLES
          ))
        ? `All your ${this.props.data.systemName} ${this.props.data.selectedFilterName === Constants.ALL_SUBSCRIPTIONS ? 'subscriptions' : 'contacts'}`
        : `${this.formatedFilterDescription()} "${this.props.data.selectedFilterName}"`}.&nbsp;
        Is that what you meant to do?
      </p>
      <p className="confirm">
        <input className="checkbox" type="checkbox"
          checked={this.state.confirmed}
          onChange={() => this.checkedFinalizeCheckBox()}
          id="confirm-checkbox" />
        <label htmlFor="confirm-checkbox">Yes</label>
      </p>
    </Col>
  }

  protected checkedFinalizeCheckBox = () => {
    this.setState({ confirmed: !this.state.confirmed })
  }

  protected isFinalizeConnectionEnabled() {
    return this.state.confirmed
  }

  protected renderFinalizeConnection() {
    return <div className={this.isFinalizeConnectionEnabled() ? 'save-button clickable-item save-button-enabled authenticate' :
      'save-button clickable-item save-button-disabled authenticate not-allowed'}
      onClick={() => this.finalizeSetup()}> Finalize connection
    </div>
  }

  protected renderFinalizeHtml() {
    return <div>
      {this.renderFinalizeConfirmation()}
      {this.renderFinalizeConnection()}
    </div>
  }

  protected isAuthenticateButtonEnabled(): boolean {
    return !this.state.isConnecting
  }

  protected connectToExisting() {
    this.setState({
      isConnecting: true,
      showExistingConnectionModal: false,
    })
    const url: string = AjaxWrapper.getServerUrl() + `/connect-to-existing-integration/${this.communityId}`
    const body = this.state.selectedExistingConnection ? { communityIntegrationConnectionId: this.state.selectedExistingConnection } : null
    AjaxWrapper.ajax(url, HttpMethod.POST, body)
    .then((result) => {
      if (!!result.success) {
        window.location.href = Constants.INTEGRATIONS_CONFIG_URL + '?id=' + result.payload.communitySystemId + '&operation=' + `${result.operation}${Constants.SUCCESS}`
      } else {
        this.setState({
          isConnecting: false,
        })
        const errMsg = result.payload ? result.payload.message : ''
        this.props.context.store.dispatch(Flash.showFlashError(errMsg))
      }
    })
    .catch((err) => {
      const operation = `${body ? Constants.UPDATE : Constants.CREATE}${Constants.FAILURE}`
      const params = {
        operation,
      }
      this.setQueries(params)
      this.setState({ addFailure: true, isLoading: false, message: operation })
      // eslint-disable-next-line no-console
      console.log('error: ', err)
      let message = ''
      try {
        message = err.response.message
      } catch (e) {
        message = err.message
      }
      this.props.context.store.dispatch(Flash.showFlashError(message))
    })
  }

  protected confirmConnect() {
    const url = this.buildPopUpUrl()
    this.setState({
      isConnecting: true,
      showExistingConnectionModal: false,
    })
    openSignInWindow(url, this.props.data.systemName)
  }

  protected connect() {
    if (this.state.existingConnections.length > 0) {
      this.setState({
        showExistingConnectionModal: true,
      })
    } else {
      this.confirmConnect()
    }
  }

  protected openSignInPopup(url) {
    openSignInWindow(url, this.props.data.systemName)
  }

  protected generateRasaApiKey(communityIdentifier: string) {
    if (!this.props.data.id || this.state.isKeyCreator || !this.props.data.is_active) {
      this.setState({ isLoading: true }, () => {
        const url: string = AjaxWrapper.getServerUrl() + `/integrations/${this.props.data.systemName}/${communityIdentifier}`
        const body = this.props.data.id ? { communitySystemId: this.props.data.id } : null
        AjaxWrapper.ajax(url, HttpMethod.POST, body)
          .then((result) => {
            this.props.loadRecord(this.communityId, result.communitySystemId)
            const params = {
              id: result.communitySystemId,
              operation: `${body ? Constants.UPDATE : Constants.CREATE}${Constants.SUCCESS}`,
            }
            this.setQueries(params)
            this.setState({ isLoading: false, isKeyCreator: true })
          })
          .catch((err) => {
            const operation = `${body ? Constants.UPDATE : Constants.CREATE}${Constants.FAILURE}`
            const params = {
              operation,
            }
            this.setQueries(params)
            this.setState({ addFailure: true, isLoading: false, message: operation })
            // eslint-disable-next-line no-console
            console.log('error: ', err)
            let message = ''
            try {
              message = err.response.message
            } catch (e) {
              message = err.message
            }
            this.props.context.store.dispatch(Flash.showFlashError(message))
          })
      })
    }
  }

  protected disconnect() {
    const { systemName } = this.props.data
    if ((systemName !== IntegrationSystems.Zapier && systemName !== IntegrationSystems.API) ||
      (this.state.isKeyCreator)) {
      this.setState({ isLoading: true })
      this.props.propertiesChanged({ is_active: false })
      if (systemName === IntegrationSystems.Mailchimp) {
        this.props.propertiesChanged({ audienceName: null })
      }
      this.props.saveRecord(this.props.data.id)
        .then((result) => {
          this.props.context.store.dispatch(Flash.showFlashMessage(Integrations.notificationMessages.DisconnectSuccess))
          this.setState({
            authenticated: false,
            dropdownAvailable: false,
            isLoading: false,
          })
        })
        .catch((err) => {
          this.setState({ isLoading: false, addFailure: true, message: 'UpdateFailure' })
        })
    }
  }

  protected reconnect() {
    if (this.state.isKeyCreator || !this.props.data.is_active) {
      this.setState({ isLoading: true }, () => {
        this.props.propertiesChanged({ is_active: true })
        this.props.saveRecord(this.props.data.id)
          .then((result) => {
            this.props.loadRecord(this.communityId, this.props.data.id)
              .then(() => this.setState({ isLoading: false, isKeyCreator: true }))
            this.props.context.store.dispatch(Flash.showFlashMessage(
              Integrations.notificationMessages.ReconnectSuccess))
          })
          .catch((err) => {
            this.setState({ isLoading: false, addFailure: true, message: 'UpdateFailure' })
          })
      })
    }
  }

  protected syncNow() {
    if (!this.state.isSyncing) {
      if (this.props.data.id) {
        this.setState({
          isLoading: true,
          isSyncing: true,
        })
        const url: string = AjaxWrapper.getServerUrl() + `/integrations/${this.communityId}/sync-now/${this.props.data.id}`
        AjaxWrapper.ajax(url, HttpMethod.POST, {}).then((result) => {
          this.setState({
            isLoading: false,
          })
          this.props.context.store.dispatch(Flash.showFlashMessage(Integrations.notificationMessages.SyncNowSuccess))
          this.updateSyncStatus()
        }).catch((ex) => {
          this.setState({
            isLoading: false,
            isSyncing: false,
          })
        })
      }
    }
  }

  protected updateSyncStatus(): Promise<boolean> {
    if (this.props.data.id && this.props.data.is_active) {
      const url: string = AjaxWrapper.getServerUrl() + `/integrations/${this.communityId}/sync-status/${this.props.data.id}`
      return AjaxWrapper.ajax(url, HttpMethod.GET, null)
        .then((result) => {
          let returnValue = false
          if (['Pending', 'In-Progress'].indexOf(result.status) > -1) {
            returnValue = true
          }
          this.setState({ isSyncing: returnValue })
          return returnValue
        })
    } else {
      return Promise.resolve(false)
    }
  }

  protected getExistingConnections() {
    new Dataset().loadCommunityDataset('communityIntegrationConnectionsForAccount', this.communityId, 
      [{param: 'systemName', value: this.props.data.systemName}]
    ).then((result) => {
      this.setState({
        existingConnections: result[0],
      })
    })
  }

  protected getCustomFields(): Promise<boolean> {
    if (Constants.KEY_BASED_INTEGRATIONS.some((system) => system === this.props.data.systemName)) {
      return Promise.resolve(true)
    }
    if (this.state.queries.id) {
      const url: string = `${AjaxWrapper.getServerUrl()}/integrations/${this.communityId}/custom-fields/${this.state.queries.id}`
      return AjaxWrapper.ajax(url, HttpMethod.GET, null).then((result) => {
        this.setState({
          customFields: result
        })
        return true
      }).catch((err) => {
        return Promise.resolve(true)
      })
    }
  }

  protected getFilters(): Promise<boolean> {
    // Skip filter call if systemName is in the list that do not support filters
    if (Constants.KEY_BASED_INTEGRATIONS.some((system) => system === this.props.data.systemName)) {
      return Promise.resolve(true)
    }
    if (this.supportsFilter && this.state.queries.operation && this.state.queries.operation.includes('Success')) {
      const url: string = `${AjaxWrapper.getServerUrl()}/integrations/${this.communityId}/filters/${this.state.queries.id}`
      return AjaxWrapper.ajax(url, HttpMethod.GET, null).then((result) => {
        const filters = result
        if (filters && typeof (filters) !== 'string') {
          const foundSelectedFilter = filters.find((f) => this.props.data.selectedFilterId &&
            f.value.toString() === this.props.data.selectedFilterId.toString())
          if (foundSelectedFilter) {
            this.setState({ filterSelected: true })
          }
          this.setState({
            filters: this.formatFilters(filters, this.props.data.systemName),
          })
        } else {
          this.setState({
            contactClientSuccess: 'Something went wrong, please contact our Support Team.',
          })
        }

        return true
      }).catch((err) => {
        this.setState({
          contactClientSuccess: 'Something went wrong, please contact our Support Team.',
        })
        return Promise.resolve(true)
      })
    } else {
      return Promise.resolve(true)
    }
  }

  protected formatFilters(filters, systemName) {
    // retrieve filter types
    const types = filters.reduce((acc, cur) => {
      const result = [...acc]
      if (cur.type && !result.includes(cur.type)) {
        result.push(cur.type)
      }
      return result
    }, [])
    // create a header for each type and add a divider if more than one type
    const mappedFilters = []
    types.forEach((type, i) => {
      mappedFilters.push({ name: Constants.FilterTypeToNameMap[type], category: 'header' })
      filters.forEach((filter, j) => {
        if (filter.type === type) {
          mappedFilters.push(filter)
        }
        if (types.length > 1 && i !== types.length - 1 && j === filters.length - 1) {
          mappedFilters.push({ type: 'divider' })
        }
      })
    })
    return mappedFilters
  }

  protected getFiltersByType(filters, filterType) {
    const mappedFilters = []
    filters.forEach((filter, i) => {
      if (filter.type === filterType) {
        mappedFilters.push(filter)
      }
    })
    return mappedFilters
  }

  protected getContacts(communitySystemId: number, selectedFilterId: string, selectedFilterName: string) {
    this.setState({
      isGettingContactsCount: true,
    })
    const url: string = AjaxWrapper.getServerUrl() + '/integrations/contacts'
    AjaxWrapper.ajax(url, HttpMethod.POST, { communitySystemId, selectedFilterId, selectedFilterName })
      .then((result) => {
        this.setState({
          contactsCount: result,
          isGettingContactsCount: false,
        })
      })
  }

  protected finalizeSetup() {
    const consentValue = sessionStorage.getItem(Constants.CONSENT_INTEGRATIONS_IMPORT)
    const consent = {
      type: Constants.CONSENT_INTEGRATIONS_IMPORT,
      value: consentValue === 'true',
    }
    if (this.state.confirmed) {
      this.setState({
        isUpdating: true,
      })
      this.props.propertiesChanged({ is_active: true })
      this.props.saveRecord(this.props.data.id)
        .then(() => {
          this.setState({
            isUpdating: false,
            dropdownAvailable: false,
          })
          sendConsent(this.communityId, consent)
          this.context.store.dispatch(BannerActions.closeBanner(BannerType.COMMUNITY_SYSTEM_DISCONNECTED))
        })
        .catch((ex) => {
          this.props.propertiesChanged({ is_active: false })
        })
    }
  }
  protected clearCustomFilters() {
    // do nothing
  }

  protected onDropdownChange(e) {
    this.clearCustomFilters()
    this.setState({
      showCustomFilter: e.target.innerText === Constants.CUSTOM_FILTER,
    })
    if (Integrations.baseDropDownOptions.includes(e.target.innerText)) {
      this.props.propertiesChanged({ selectedFilterName: '', selectedFilterId: '' })
      this.setState({ contactsCount: 0, filterSelected: false })
    } else {
      new Promise((resolve, reject) => {
        this.props.propertiesChanged({
          selectedFilterName: e.target.innerText,
          selectedFilterId: e.target.value,
          selectedFilterType: e.target.dataset.type,
        })
        if (this.selectedDifferentFilter(e)) {
          this.props.openModal(ReconnectIntegrationModal.key, null)
        }
        resolve(null)
      }).then(() => {
          if (isTruthy(this.showContactsCount)) {
            this.getContacts(
              this.props.data.id,
              this.props.data.selectedFilterId,
              this.props.data.selectedFilterName,
            )
          }
          this.setState({ filterSelected: true })
      })
    }
  }

  protected onSegmentDropdownChange(e) {
    const selected = e ? e.value : ''
    this.props.propertiesChanged({
      fieldMappings: this.updateTargetFieldNameFieldMappings(this.props.data.fieldMappings, 'segment_code', selected),
    })
  }

  protected onEmailDropdownChange(e) {
    const selected = e ? e.value : ''
    this.props.propertiesChanged({
      fieldMappings: this.updateTargetFieldNameFieldMappings(this.props.data.fieldMappings, 'email', selected),
    })
  }

  protected onMultiSelectDropdownChange(selections: Integrations.Filter[]) {
    const filterNameSelectionString = selections.map((s) => s.label).join(', ')
    const filterIdSelectionString = selections.map((s) => s.value).join(', ')
    const selectedFilterType = !isEmpty(selections) ? selections[0].type : ''
    new Promise((resolve, reject) => {
      this.props.propertiesChanged({
        selectedFilterName: filterNameSelectionString,
        selectedFilterId: filterIdSelectionString,
        selectedFilterType,
      })
      if (!!this.state.existingFilter.id && this.state.existingFilter.id !== filterIdSelectionString) {
        this.props.openModal(ReconnectIntegrationModal.key, null)
      }
      resolve(null)
    }).then(() => {
      if (isTruthy(this.showContactsCount)) {
        this.getContacts(
          this.props.data.id,
          this.props.data.selectedFilterId,
          this.props.data.selectedFilterName,
        )
      }
      this.setState({ filterSelected: true })
    })
  }

  protected selectedDifferentFilter(e): boolean {
    if (this.state.existingFilter.type) {
      if (this.state.existingFilter.type === e.target.dataset.type &&
        this.state.existingFilter.id === e.target.value) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  protected isTwoWayChecked() {
    return this.props.data && this.props.data.system_api_attribute
      ? JSON.parse(this.props.data.system_api_attribute).sync_direction === 'both' ? true : false
      : false
  }

  protected isSyncNewRasaContacts() {
    return this.props.data && this.props.data.system_api_attribute
      ? JSON.parse(this.props.data.system_api_attribute).sync_new_rasa_contacts === true ? true : false
      : false
  }

  protected validateTwoWayStatus(): boolean {
    if (this.systemInfo.twoWayStatus === Integrations.IntegrationStatus.available && this.props.data.id &&
      ((!this.props.data.is_active && this.state.authenticated) || !!this.props.data.is_active)) {
        return true
    } else {
      return false
    }
  }

  protected updateFieldMappings(mappings: Integrations.FieldMapping[], type, value) {
    return mappings.map((m) => {
      if (m.rasa_field_name === type) {
        return {
          ...m,
          update_in_target: isTruthyNumeric(value),
        }
      }
      return m
    })
  }

  protected updateTargetFieldNameFieldMappings(mappings: any, type: string, value: string) {
    const index = mappings.findIndex((m) => m.rasa_field_name === type);
    if (index !== -1) {
      mappings[index] = {...mappings[index], target_field_name: value };
    } else {
      mappings.push({ rasa_field_name: type, target_field_name: value });
    }
    return mappings
  }

  protected onSyncNewContactsCheckboxChange(isSync) {
    const filterInfo = Integrations.getFilterInfo(this.props.data)
    const filterName = filterInfo.name
    const filterId = filterInfo.id
    const newSystemApiAttribute = {
      ...JSON.parse(this.props.data.system_api_attribute),
      sync_new_rasa_contacts: isSync,
    }
    newSystemApiAttribute[filterName] = this.props.data.selectedFilterName
    newSystemApiAttribute[filterId] = this.props.data.selectedFilterId
    this.props.propertiesChanged({
      fieldMappings: this.updateFieldMappings(this.props.data.fieldMappings, 'is_subscribed', isSync),
      system_api_attribute: JSON.stringify(newSystemApiAttribute),
    })
    if (this.props.data.is_active) {
      this.props.saveRecord(this.props.data.id)
        .then((result) => {
          this.props.context.store.dispatch(Flash.showFlashMessage(Integrations.notificationMessages.UpdateSuccess))
        })
    }
  }

  protected onTwoWayCheckboxChange(isChecked) {
    const filterInfo = Integrations.getFilterInfo(this.props.data)
    const filterName = filterInfo.name
    const filterId = filterInfo.id
    const newSystemApiAttribute = {
      ...JSON.parse(this.props.data.system_api_attribute),
      sync_direction: isChecked ? 'both' : 'to_rasa',
    }
    newSystemApiAttribute[filterName] = this.props.data.selectedFilterName
    newSystemApiAttribute[filterId] = this.props.data.selectedFilterId

    this.props.propertiesChanged({
      fieldMappings: this.updateFieldMappings(this.props.data.fieldMappings, 'is_subscribed', isChecked),
      system_api_attribute: JSON.stringify(newSystemApiAttribute),
    })
    if (this.props.data.is_active) {
      this.props.saveRecord(this.props.data.id)
        .then((result) => {
          this.props.context.store.dispatch(Flash.showFlashMessage(Integrations.notificationMessages.UpdateSuccess))
        })
    }
  }

  protected buildPopUpUrl(): string {
    if (this.props.clientIds) {
      const systemName = this.props.data.systemName
      return `${this.systemInfo.keyUrl}${this.encodedCommunityIdentifier}&client_id=${this.props.clientIds[systemName]}&redirect_uri=${RASA_SELF_SERVICE_API}/integrations/${systemName.toLowerCase()}-oauth-callback`
    }
  }

  protected formatedFilterDescription() {
    return this.props.data.selectedFilterName.includes(',')
    ? pluralize(this.filter.description)
    : (this.filter.description)
  }

  protected isRichIntegration = () => {
    return this.state.queries && this.state.queries.isRichIntegration && this.state.hasRichIntegrationAccess
  }

  protected isRichIntegrationChecked() {
    return this.props.data && this.props.data.system_api_attribute
      ? JSON.parse(this.props.data.system_api_attribute).is_full_integration === true
      : false
  }

  protected canSegmentation = () => {
    return this.state.queries && this.state.hasSegmentAccess
  }

  protected isFieldMappingExist(key: string) {
    return this.props.data.fieldMappings.find((m) => m.rasa_field_name === key);

  }

  protected findFieldMapping(key: string) {
    return this.props.data.fieldMappings.find((m) => m.rasa_field_name === key)
  }

  protected getFieldMappingName(key: string) {
    const customField = this.findFieldMapping(key)
    if (!customField) {
      return ''
    }
    const found = this.state.customFields.find((a) => a.value === customField.target_field_name)
    return found ? found.name : ''
  }

  private onExistingConnectionChange = (event: any) => {
    this.setState({
      selectedExistingConnection: Number(event.target.value),
    })
  }

  private renderExistingConnectionModalJSX = () => {
    return <div>
      <Modal isOpen={this.state.showExistingConnectionModal}
        className="existing-integration-connection-modal"
        size="lg"
        fade={false}
        centered={true} >
        <ModalHeader>Existing Connections Found</ModalHeader>
        <ModalBody className="existing-integration-connection-body">
          <div>
            <h4>We have found an existing integration with {this.props.data.systemName}.</h4>
              <div className='existing-integration-connections'>
                {
                  this.state.existingConnections.map((connection: any) => {
                    return <div><FormGroup check>
                      <Label check>
                        <Input onChange={this.onExistingConnectionChange}
                          value={connection.id}
                          checked={this.state.selectedExistingConnection === connection.id}
                          type="radio" name="existinConnectionRadio" />
                        {connection.community_name}
                      </Label>
                    </FormGroup></div>
                  })
                }
              </div>
              <div>Do you want to re-use this connection and select a different audience?  Or will you connect with a different account?</div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => this.connectToExisting()} disabled= {this.state.selectedExistingConnection === null}>
            Use this Connection
          </Button>
          <Button onClick={() => this.confirmConnect()}>
            Connect with a different account
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  }
}

export class ReconnectIntegrationModal extends Modals.ModalComponent {
  public static key: string = 'reconnectIntegration'
  constructor(props: Modals.ModalComponentProps) {
    super(props, ReconnectIntegrationModal.key)
  }
  protected renderChildren(data: any) {
    return (
      <span>
        Please be aware that if you update your integration settings, contacts
        that no longer meet the requirements of your new integration settings
        (audience, list, subscription type or status, etc.) will be removed.
      </span>
    )
  }
}

let windowObjectReference = null
let previousUrl = null

const openSignInWindow = (url: string, name: string) => {
  const strWindowFeatures =
    'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousUrl !== url) {
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference.focus()
  } else {
    windowObjectReference.focus()
  }
  previousUrl = url
}
