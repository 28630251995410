import { orderBy } from 'lodash'
import React, {Component} from 'react'

import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { DropdownComponent, DropdownOption, OnChangeEvent } from 'components/dropdown/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { Dataset } from 'generic/dataset'
import { Button, Row } from 'reactstrap'
import { formatDate } from 'shared_server_client/dates'
import { UpcomingIssue } from 'shared_server_client/types/schedule'
import * as GenericRedux from '../../../generic/genericRedux'
import { Article, ArticleChangeFunction, ChangeEvent } from '../types'
import './_styles.scss'
import { ActiveArticle } from './article-active'
import { BodyArticle } from './article-body'
import { BoostArticle } from './article-boost'
import { DateArticle } from './article-date'
import { EditCard } from './article-edit'
import { SourceArticle } from './article-source'

export const SortByOptions: DropdownOption[] = [
  {key: '0', value: '', description: 'Sort By'},
  {key: '1', value: 'source_name', description: 'Source'},
  {key: '2', value: 'created', description: 'Date'},
  {key: '3', value: 'title', description: 'Article Title'},
]

type UpcomingArticleProps = GenericRedux.DatasetContainerComponentProps<Article>

interface SelectedSourceHash {
  [name: string]: boolean,
}

interface UpcomingArticlesState {
  articles: Article[],
  communityId: string,
  loading: boolean,
  nextIssue?: UpcomingIssue,
  selected?: SelectedSourceHash,
  showActiveOnly: boolean,
  showPopUp: boolean,
  selectedSortBy: DropdownOption,
}

export class MobileUpcomingArticlesComponent extends Component<UpcomingArticleProps, UpcomingArticlesState> {
public static contextType = RasaContext;

  constructor(props: UpcomingArticleProps) {
    super(props);
    this.state = {
      articles: [],
      communityId: '',
      loading: true,
      nextIssue: null,
      selected: {},
      showActiveOnly: true,
      showPopUp: false,
      selectedSortBy: SortByOptions[0],
    }
  }

  public componentDidMount = () => {
    this.context.user.init().then(({activeCommunity}) => {
      this.setState({
        communityId: activeCommunity.communityId,
        loading: false,
        nextIssue: activeCommunity.nextIssue,
      }, () => {
        this.refreshArticles()
      })
    })
  }

  public render() {
    return this.state.loading ?
      <div className="upcoming-articles-page">
        <div className="bouncing-balls">
          <Loading size="64"/>
        </div>
      </div> :
      <div className="mobile-upcoming-articles">
        {this.state.nextIssue ?
        <div className="mobile-next-newsletter-wrapper">
            Next newsletter on &nbsp;
            {formatDate(this.state.nextIssue.date)}
        </div>
        : null}
        <div className="upcoming-header-wrap">
          <div className="sortby-dropdown">
            <DropdownComponent data={SortByOptions}
                                selected={this.state.selectedSortBy.key}
                                onChange={this.sortByChanged}/>
          </div>
        {/*
          <Button className="upcoming-article-button" onClick={() => this.props.push('/content')}>
            <img src="https://cdn.zeplin.io/5d6023941db0449b12f2de7f/assets/3FC2EACA-BA79-4A4E-B77E-06E081E4B790.svg"
            className="panel-generic-item-icon" style={{width: 25, height: 25}}></img>
            <span className="button-text">
              Upcoming
            </span>
        </Button>*/}
          <Button className="upcoming-article-button" onClick={() => this.props.push('/content/addarticle')}>
            <img src="https://cdn.zeplin.io/5d6023941db0449b12f2de7f/assets/3FC2EACA-BA79-4A4E-B77E-06E081E4B790.svg"
                className="panel-generic-item-icon" style={{width: 25, height: 25}}></img>
            <span className="button-text">
              Add Article
            </span>
          </Button>
        </div>
        {this.state.articles.map((a: Article, index: number) => {
            return <ArticleCard key={index}
                                push={this.props.push}
                                article={a}
                                onArticleChange={this.articleChanged}
            />
          })}
      </div>
  }

  public sortByChanged = (e: OnChangeEvent) => {
    this.setState({
      articles: this.sort(this.state.articles, e.selected),
      selectedSortBy: e.selected,
    })
  }

  private sort = (articles: Article[], sort: DropdownOption): Article[] => {
    const order = sort.value === 'created' ? 'desc' : 'asc'
    return orderBy(articles, [(a) => a[sort.value]], order)
  }

  private articleChanged = (articleId: number, event: ChangeEvent) => {
    this.context.entityMetadata.getEntityObject('communityArticle').then(
                      (entityObject: BaseClientEntity) => {
                        entityObject.setRecordId(this.state.communityId, articleId)
                        entityObject.data[event.field] = event.value
                        entityObject.save()
    })
  }

  private refreshArticles() {
    this.setState({
      articles: [],
    })
    new Dataset().loadCommunityDataset('communityUpcomingContentPool', this.state.communityId)
      .then((response) => {
        const articlesToDisplay = response[0] && this.state.nextIssue ?
        orderBy(response[0]
          .filter((article) => Date.parse(article.created) < Date.parse(this.state.nextIssue.cutoff)), ['is_active', 'created'], ['desc', 'desc']) :
        response[0] ?
        orderBy(response[0], ['is_active', 'created'], ['desc', 'desc']) :
        []
        this.setState({
          articles: articlesToDisplay})
      })
}
}
export const MobileUpcomingArticles = GenericRedux.registerNewDatasetContainerComponent<Article>(
  MobileUpcomingArticlesComponent, 'upcoming_articles')

interface ArticleProps {
  article: Article,
  onArticleChange: ArticleChangeFunction,
  push: any,
}
class ArticleCard extends Component<ArticleProps> {
  public render() {
    return <div>
        <div className="mobile-article-card">
          <Row className="mobile-source-date">
            <div className="mobile-article-source">
              <SourceArticle
                articleSource={this.props.article.source}
                sourceTitle={this.props.article.source_name}
                siteName={this.props.article.site_name}/>
            </div>
            <div className="mobile-article-date">
              <DateArticle articleDate={this.props.article.created}/>
            </div>
          </Row>
          <Row className="mobile-title-image">
            <div className="mobile-article-title">
              <BodyArticle articleImage={this.props.article.hosted_image_url}
                          articleTitle={this.props.article.title}
                          description={this.props.article.description}
                          articleUrl={this.props.article.url}/>
            </div>
          </Row>
          <Row className="active-boost-like-edit">
            <div className="mobile-article-active">
              <ActiveArticle isActive={this.props.article.is_active}
                             onChange={(e) => this.articleChanged('is_active', e)}/>
            </div>
            <div className="mobile-article-boost">
              <BoostArticle boostLevel={this.props.article.boost_level}
                             onChange={(e) => this.articleChanged('boost_level', e)}/>
            </div>
            <div className="mobile-article-edit">
              <EditCard onClick={this.editPost}/>
            </div>
          </Row>
        </div>
    </div>
  }

  private articleChanged = (field: string, event: ChangeEvent) => {
    this.props.onArticleChange(this.props.article.id, {
      ...event,
      field,
    })
  }

  private editPost = () => {
    this.props.push(`${DashboardMenuOption.content}/editarticle?id=${this.props.article.id}`)
  }
}
