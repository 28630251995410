import { RasaContext } from 'context'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import React from 'react'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { Loading } from '../loading'
import {
  EmailAttributes, getAttribute,
} from '../email-layout/constants'
import { DashboardMenuOption } from '../dashboard-menu/constants'
import { EmailTemplates, StripoDefaultTemplates } from '../../shared_server_client/constants'
import { NewspaperTemplate } from '../icons/template-newspaper'
import { ClassicTemplate } from '../icons/template-classic'
import { BigAndBoldTemplate } from '../icons/template-big-and-bold'
import { InsightsTemplate } from '../icons/template-insights'
import {Dataset, DatasetParam} from '../../generic/dataset'
import { isNil } from 'lodash'
import { BaseClientEntity, RecordIdType } from '../../generic/baseClientEntity'
import { HeaderComponent } from '../header/component'
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap'
import { ProgressBar } from '@progress/kendo-react-progressbars'
import { UpgradeButton } from '../upgrade/button'
import { UPGRADE_BUTTON_COLOR } from '../upgrade/constants'
import './_styles.scss'
import * as GenericRedux from '../../generic/genericRedux'
import * as GA from '../../google-analytics'
import * as Constants from './constants'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import {AjaxWrapper, HttpMethod} from "../../generic/ajaxWrapper"
import * as GlobalConstants from '../../constants'
import {removeExtension} from "./utils";

const NO_TEMPLATE_SELECTED = '-1'
const NO_TEMPLATE_SELECTED_LABEL = 'Select'

interface DesignDataProps {
  onChange: any,
  setConfigData?: any
}

type DesignProps = GenericRedux.AllComponentProps<DesignDataProps> & DesignDataProps

type emailTemplate = {
  id: string,
  body: string,
  fileName: string,
  type: 'stable' | 'custom',
}

interface DesignState {
  communityId: string,
  communityPartnerData?: any,
  changes: any,
  deleteTemplateIndex: number,
  dirtySections?: any,
  emailLayoutConfig: any,
  emailTemplates: emailTemplate[],
  emailTemplatesData: any[],
  isCustomTemplateAllowed: boolean,
  isSuperUser: boolean,
  originalProps?: any,
  templateDeleteConfirmationModal: boolean,
  selected: string,
  wasPreviouslyDirty: boolean,
  plan?: BillingPlan.BillingPlan,
  preSave: EmailTemplates
}

class TemplateLibraryComponent extends RasaReactComponent<DesignProps, DesignState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private emailLayoutId: number = null

  constructor(props: DesignProps) {
    super(props, 'email_layout', {
      communityId: '',
      emailLayoutConfig: {},
      emailTemplates: [],
      emailTemplatesData: [],
      deleteTemplateIndex: -1,
      isCustomTemplateAllowed: false,
      isSuperUser: false,
      changes: {},
      originalProps: {},
      dirtySections: {},
      templateDeleteConfirmationModal: false,
      selected: null,
      wasPreviouslyDirty: false,
      preSave: EmailTemplates.newspaper
    })
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
    ])
      .then(([activeCommunity, role]) => {
        const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
        if (activeCommunity.communityInfo) {
          this.emailLayoutId = activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].id
          this.loadRecord(activeCommunity.communityId, this.emailLayoutId)
            .then(() => {
              this.setState({
                communityId: activeCommunity.communityId,
                emailLayoutConfig: this.props,
                isCustomTemplateAllowed: avlFeatures.indexOf(BillingPlanDetailCode.EMAIL_TEMPLATES) > -1,
                isSuperUser: role === Roles.super_admin,
                plan: activeCommunity.billingInfo.currentPlan,
                preSave: getAttribute(this.props, EmailAttributes.emailTemplate)
              }, () => {
                if (this.state.isCustomTemplateAllowed) {
                  this.loadEmailTemplates(activeCommunity.communityId)
                }
              })
            })
        }
      })
  }

  public mapPropsToEntityObject(entityObject: BaseClientEntity): void {
    Object.keys(this.state.changes).forEach((key: string) => {
      entityObject.data[key] = this.state.changes[key]
    })
  }

  public propertyChanged(field: string, value: string) {
    this.setState({
      isDirty: true,
      changes: {
        ...this.state.changes,
        [field]: value,
      },
    })
    this.props.propertyChanged(field, value)
  }

  public saveLayoutAndPublish = (recordId: RecordIdType) => {
    this.saveRecord(recordId).then(() => {
      const templateName = getAttribute(this.props, EmailAttributes.emailTemplate)
      this.setState({
        preSave: templateName,
      })
      this.clearChanges()
      // Need to reload page for the preview to reflect the new template.
      window.location.reload()
    }).catch((err) => {
      // console.log(err)
    })

  }

  public render = () => {
    return (
      <div className="templates-design py-4 py-md-5">
        {this.state.isLoading || !this.state.plan || this.state.isSaving ? <Loading size="64"/> :
          <div className="container">
            <div className="template-editor">
              <div className="header-container">
                <HeaderComponent
                  title={''}
                  subTitle={'Template Library'}
                  description={['Select a template from below and begin designing.']}
                />
                <this.limitReached/>
              </div>
              <div className="section">
                <div className="row">
                  <strong className="savedHeading">Out of the Box Templates</strong>
                  <div className="col-12 col-sm-6 col-md-6 d-flex">
                    <button
                      className={`${this.buttonClass([EmailTemplates.default, EmailTemplates.oldDefault], 'classic')}`}
                      onClick={(e) => this.propertyChanged(EmailAttributes.emailTemplate, EmailTemplates.default)}>
                      <i className="fa fa-check-circle"
                         onClick={() => this.saveLayoutAndPublish(this.emailLayoutId)}></i>
                      <div className="d-flex align-items-start">
                        <ClassicTemplate colors={this.state.emailLayoutConfig}/>
                        <div className="templates-button-content">
                          <div className="templatetitle templates-title">
                            Classic
                          </div>
                          <span className="descriptionoftem">
                         A classic. Effective and simple. Includes small image articles.
                        </span>
                        </div>
                      </div>
                      <div className="templates-action-btns-wrap d-flex align-items-center justify-content-end">
                        <button type="button" className="btn btn-secondary ta-button"
                                onClick={() => this.onClick(StripoDefaultTemplates.default)}
                                disabled={this.disableButton(EmailTemplates.default)}>Edit
                        </button>
                      </div>
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 d-flex">
                    <button
                      className={`${this.buttonClass([EmailTemplates.newspaper, EmailTemplates.oldNewspaper], 'news')}`}
                      onClick={(e) => this.propertyChanged(EmailAttributes.emailTemplate, EmailTemplates.newspaper)}>
                      <i className="fa fa-check-circle"
                         onClick={() => this.saveLayoutAndPublish(this.emailLayoutId)}></i>
                      <div className="d-flex align-items-start">
                        <NewspaperTemplate colors={this.state.emailLayoutConfig}/>
                        <div className="templates-button-content">
                          <div className="templatetitle templates-title">
                            Newspaper
                          </div>
                          <span className="descriptionoftem">
                            A newspaper style newsletter template. Includes a large first article.
                          </span>
                        </div>
                      </div>
                      <div className="templates-action-btns-wrap d-flex align-items-center justify-content-end">
                        <button type="button" className="btn btn-secondary ta-button"
                                onClick={() => this.onClick(StripoDefaultTemplates.newspaper)}
                                disabled={this.disableButton(EmailTemplates.newspaper)}>Edit
                        </button>
                      </div>
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 d-flex">
                    <button
                      className={`${this.buttonClass([EmailTemplates.bigAndBold, EmailTemplates.oldBigAndBold], 'bold')}`}
                      onClick={(e) => this.propertyChanged(EmailAttributes.emailTemplate, EmailTemplates.bigAndBold)}>
                      <i className="fa fa-check-circle"
                         onClick={() => this.saveLayoutAndPublish(this.emailLayoutId)}></i>
                      <div className="d-flex align-items-start">
                        <BigAndBoldTemplate colors={this.state.emailLayoutConfig}/>
                        <div className="templates-button-content">
                          <div className="templatetitle templates-title">
                            Big and Bold
                          </div>
                          <span className="descriptionoftem">Large size article images and bolder fonts.</span>
                        </div>
                      </div>
                      <div className="templates-action-btns-wrap d-flex align-items-center justify-content-end">
                        <button type="button" className="btn btn-secondary ta-button"
                                onClick={() => this.onClick(StripoDefaultTemplates.bigAndBold)}
                                disabled={this.disableButton(EmailTemplates.bigAndBold)}>Edit
                        </button>
                      </div>
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 d-flex">
                    <button
                      className={`${this.buttonClass([EmailTemplates.insights], 'insights')}`}
                      onClick={(e) => this.propertyChanged(EmailAttributes.emailTemplate, EmailTemplates.insights)}>
                      <i className="fa fa-check-circle"
                         onClick={() => this.saveLayoutAndPublish(this.emailLayoutId)}></i>
                      <div className="d-flex align-items-start">
                        <InsightsTemplate colors={this.state.emailLayoutConfig}/>
                        <div className="templates-button-content">
                          <div className="templatetitle templates-title">
                            Insights
                          </div>
                          <span className="descriptionoftem">Focused on text and information.</span>
                        </div>
                      </div>
                      <div className="templates-action-btns-wrap d-flex align-items-center justify-content-end">
                        <button type="button" className="btn btn-secondary ta-button"
                                onClick={() => this.onClick(StripoDefaultTemplates.insights)}
                                disabled={this.disableButton(EmailTemplates.insights)}>
                          Edit
                        </button>
                      </div>
                    </button>
                  </div>
                  {this.state.emailTemplates.length > 0 && this.state.isCustomTemplateAllowed &&
                    <>
                      <strong className="savedHeading">Saved Templates</strong>
                      {this.state.emailTemplates.map((template, index) => (
                          <div className="col-12 col-sm-6 col-md-4 d-flex" key={index}>
                            <button
                              onClick={(e) => this.propertyChanged(EmailAttributes.emailTemplate, this.tokenizeTemplateName(template.fileName, template.type))}
                              className={`${this.buttonClass([template.fileName], 'custom')}`}>
                              <i className="fa fa-check-circle"
                                 onClick={() => this.saveLayoutAndPublish(this.emailLayoutId)}></i>
                              <div className="d-flex align-items-start">
                                <ClassicTemplate colors={this.state.emailLayoutConfig}/>
                                <div className="templates-button-content">
                                  <div onClick={() => this.props.push(DashboardMenuOption.templateLibrary)}
                                       className="templatetitle templates-title">
                                    {template.fileName}
                                  </div>
                                  <div
                                    className={'descriptionoftem'}>
                                    {template.type === Constants.TEMPLATE_VERSION.V2 ? 'Custom rasa.io Template' : 'Imported HTML'}
                                  </div>
                                </div>
                              </div>
                              <div className="templates-action-btns-wrap d-flex align-items-center justify-content-end">
                                {template.type === Constants.TEMPLATE_VERSION.V2 && (
                                  <>
                                    {!this.maxLimit().maxLimitReached &&
                                      <button type="button" className="btn btn-secondary ta-button"
                                              onClick={() => this.copyTemplate(template)}
                                              disabled={this.disableButton(template.fileName)}>
                                        Copy
                                      </button>
                                    }
                                    <button type="button" className="btn btn-secondary ta-button"
                                            onClick={() => this.onClick(template.fileName)}
                                            disabled={this.disableButton(template.fileName)}>
                                      Edit
                                    </button>
                                  </>
                                )}
                                <button type="button" className="btn btn-secondary ta-button"
                                        disabled={this.disableButton(template.fileName) ||
                                          this.state.preSave === template.fileName}
                                        onClick={() => this.deleteTemplateConfirmation(index)}>
                                  Delete
                                </button>
                              </div>
                            </button>
                          </div>
                        ))
                      }
                    </>
                  }
                </div>
              </div>
            </div>
            {this.renderDeleteConfirmationModal()}
          </div>
        }
      </div>)
  }
  protected limitReached = () => {
    const {maxTemplateCount, isUnlimited, maxLimitReached} = this.maxLimit()
    return <div className="design-right-side">
      <div className="template-button-container">
        {!isUnlimited && this.state.isCustomTemplateAllowed ?
          <div className="div-progress-bar">
            <ProgressBar
              min={0}
              className={`progress-bar${maxLimitReached ? '-red' : ''}`}
              max={maxTemplateCount}
              value={this.state.emailTemplates.length}
              labelVisible={false}/>
            <div className="progress-bar-label">
              <div>Templates used</div>
              <div>{this.state.emailTemplates.length}/{maxTemplateCount}</div>
            </div>
          </div> : null}
        {maxLimitReached || !this.state.isCustomTemplateAllowed ?
          <div>
            <UpgradeButton color={UPGRADE_BUTTON_COLOR.WHITE} showIcon={true}
                           source={GA.UpgradeSource.CustomTemplate} text="Upgrade"/>
          </div> :
          <div className="add-sources-button-text-section content-right">
            <Button className="w-100" onClick={() => this.props.push(Constants.TEMPLATE_ADD_PAGE)}>
              <span className="button-text">
                Create Template
              </span>
            </Button>
            <a className="link" href={GlobalConstants.RASA_HELP_BASE_URL} target="_blank" rel="noopener">
            <div className="verbiage">
              <div>How to utilize the custom rasa.io template builder</div>
            </div>
            </a>
          </div>}
      </div>
      {maxLimitReached ?
        <div className="max-limit-description">
          You've reached your templates limit, please upgrade your plan to add new template.
        </div> : null}
    </div>
  }

  private maxLimit = () => {
    const maxTemplateCount = BillingPlan.getCurrentPlanMaxTemplate(this.state.plan)
    const isUnlimited = maxTemplateCount === BillingPlan.UNLIMITED_VALUE || maxTemplateCount === -1
    const maxLimitReached = !isUnlimited &&
      this.state.emailTemplates.length >= maxTemplateCount
    return {
      maxTemplateCount,
      isUnlimited,
      maxLimitReached
    }
  }
  

  private loadEmailTemplates = (communityId: string) => {
    const params: DatasetParam[] = [
      { param: 'load_stripo', value: '1' },
    ]
    return new Dataset().loadCommunityDataset('htmlTemplates', communityId, params)
      .then((htmlTemplates) => {
        const data = [{
          key: NO_TEMPLATE_SELECTED_LABEL,
          value: NO_TEMPLATE_SELECTED,
        },
          ...htmlTemplates[0].map((x) => {
            return {
              key: x.fileName,
              value: x.id,
            }
          })]
        htmlTemplates[0].map((x) => {
          x.id = removeExtension(x.id)
          x.fileName = removeExtension(x.fileName)
          return x
        })
        this.setState({
          emailTemplatesData: data,
          emailTemplates: htmlTemplates[0],
          selected: this.getSelectedTemplate(),
        })
      })
  }

  private onClick = (template: string) => {
    this.props.push(`${DashboardMenuOption.stripo}?template=${this.unTokenizeTemplateName(template)}`)
  }

  private getSelectedTemplate = () => {
    const currentTemplate = getAttribute(this.props, EmailAttributes.emailTemplate)
    return !isNil(currentTemplate) && currentTemplate.indexOf('custom') > -1 &&
    this.state.emailTemplatesData.length > 0 ? this.state.emailTemplatesData.filter((x) =>
        x.value === currentTemplate.split(':')[1])[0].key :
      this.state.emailTemplatesData.length > 0 ? this.state.emailTemplatesData[0].key : ''
  }

  private buttonClass = (templateNames: string[], prefix: string): string => {
    const selectedTemplate = this.unTokenizeTemplateName(getAttribute(this.props, EmailAttributes.emailTemplate))
    const isSelected: boolean = templateNames.includes(selectedTemplate)
      || (templateNames.includes('custom') && selectedTemplate && selectedTemplate.indexOf('custom') > -1)
    const isSaved: boolean = templateNames.includes(this.state.preSave)
    const isPublish: boolean = getAttribute(this.props, EmailAttributes.emailTemplate) === this.state.preSave
    return `template ${prefix}-temp ${isSelected ? 'selected' : ''} ${isSaved ? "saved" : ''} ${isPublish ? "publish" : ''}`
  }
  private clearChanges = () => {
    this.setState({
      changes: {}
    })
  }

  private copyTemplate = (template: emailTemplate) => {
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.state.communityId}/template/copy`
    this.setState({
      isSaving: true,
    })
    AjaxWrapper.ajax(url, HttpMethod.POST, {
      templateId: template.id,
      path: `copy_${template.fileName}`,
    })
      .then(() => {
        this.loadEmailTemplates(this.state.communityId).then(() =>{
          this.setState({
            isSaving: false,
          })
        })
      })
      .catch(() => {
        this.setState({
          isSaving: false,
        })
      })
  }
  private deleteTemplate() {
    this.setState({
      isSaving: true,
    })
    const templateId = this.state.emailTemplates[this.state.deleteTemplateIndex].id
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.state.communityId}/template/${templateId}`
    AjaxWrapper.ajax(url, HttpMethod.DELETE, null)
      .then(() => {
        this.state.emailTemplates.splice(this.state.deleteTemplateIndex, 1)
        this.setState({
          isSaving: false,
          templateDeleteConfirmationModal: false,
          deleteTemplateIndex: -1
        })
      })
      .catch(() => {
        this.setState({
          isSaving: false,
        })
      })
  }

  private deleteTemplateConfirmation(index: number) {
    this.setState({
      templateDeleteConfirmationModal: true,
      deleteTemplateIndex: index
    })
  }

  private closeDeleteConfirmationModal = () => {
    this.setState({
      templateDeleteConfirmationModal: false,
      deleteTemplateIndex: -1
    })
  }

  private renderDeleteConfirmationModal(): JSX.Element {
    return (
      <div>
        <Modal isOpen={this.state.templateDeleteConfirmationModal}>
          <ModalBody className="delete-modal">
            <div>
              <h4>Are You Sure?</h4>
              <p>Are you sure you want to delete this template?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.deleteTemplate()}>
              Yes
            </Button>
            <Button onClick={this.closeDeleteConfirmationModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
  private tokenizeTemplateName = (template_name:string, template_version: string) => {
    return template_version === Constants.TEMPLATE_VERSION.V2 ? `stripo:${template_name}` : template_name
  }
  private disableButton = (template: string) => {
    return this.unTokenizeTemplateName(getAttribute(this.props, EmailAttributes.emailTemplate)) !== template
  }
  private unTokenizeTemplateName = (template_name:string) => {
    return template_name.replace('stripo:', '')
  }
}

export const TemplateLibrary = GenericRedux.registerNewComponent<any>(
  TemplateLibraryComponent,
  'new_design_layout',
  {})
