import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { RasaContext } from 'context'
import { HeaderComponent } from 'components/header/component'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as React from 'react'
import { Button, Modal, ModalBody, ModalFooter, Row, Input } from 'reactstrap'
import { DropdownComponent, OnChangeEvent } from 'components/dropdown/component'

import './_styles.scss'

interface PromptTemplate {
  id: number,
  sequence: number,
  prompt: string,
  options: string,
}

interface EditablePromptTemplate extends PromptTemplate {
  changedFields: string[],
  invalidFields: string[],
}

interface PromptEngine {
  id: number,
  is_default: boolean,
  engine_name: string,
  engine_version: string,
  templates: PromptTemplate[],
}

interface PromptType {
  engines: PromptEngine[],
  id: number,
  name: string,
  description: string,
}

const NO_PROMPT_TYPE: PromptType = {
  engines: [
    {
      id: -1,
      is_default: false,
      engine_name: '',
      engine_version: '',
      templates: [],
    },
  ],
  id: -1,
  name: '',
  description: ''
}

interface State {
  identifier: string,
  promptTypes: any[]
  selectedPrompt: number,
  selectedEngine: number,
  editableTemplates: EditablePromptTemplate[],
  editingTemplates: boolean,
}

const emptyState: State = {
  identifier: '',
  promptTypes: [],
  selectedPrompt: -1,
  selectedEngine: -1,
  editableTemplates: [],
  editingTemplates: false,
}

class RexPromptsComponent extends RasaReactComponent<any, State>{
  public static contextType = RasaContext;

  constructor(props: any) {
    super(props, "rexPrompts", emptyState)
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        identifier: activeCommunity.identifier,
      }, () => {
        return new Dataset().loadCommunityDataset(
          'aiPromptTypes',
          activeCommunity.communityId,
          []
        ).then((results) => {
          this.setState({
            promptTypes: results
          })
        })
      })
    })
  }

  public render = () => {
    return <div className="rex-ai-prompts">
      <HeaderComponent
        icon="t-rex"
        title={'AI Prompts'}
        subTitle='Manage the AI Prompts'
        description={[
          'View and edit AI prompts used to guide t-rex.',
        ]}
      />
      <Row>
        { this.promptTypesTable()}
      </Row>
      {this.previewModal()}
    </div>
  }

  private promptTypesTable = () => {
    return <Grid data={this.state.promptTypes}>
      <GridColumn field="name" title="Prompt Type" />
      <GridColumn field="description" title="Description" />
      <GridColumn title="Primary" cell={this.primaryEngineCell}/>
      <GridColumn title="Alternates" cell={this.secondaryEngineCell}/>
      <GridColumn title="View" cell={this.viewPromptsCell}/>
    </Grid>
  }

  private engineLabel = (
    engine: PromptEngine, showDefault: boolean, isDirty: boolean
  ): string  => {
    const labelParts = []
    if (isDirty) {
      labelParts.push('*')
    }
    labelParts.push(engine.engine_name)
    labelParts.push(engine.engine_version)
    if (showDefault && engine.is_default) {
      labelParts.push('- DEFAULT')
    }
    return labelParts.join(' ')
  }

  private primaryEngineCell = (props: any) => {
    return <td>
      <span>{this.engineLabel(props.dataItem, false, false)}</span>
    </td>
  }

  private secondaryEngineCell = (props: any) => {
    const secondaryEngines = props.dataItem.engines.filter((engine: PromptEngine) => {
      return engine.engine_name !== props.dataItem.engine_name ||
             engine.engine_version !== props.dataItem.engine_version
    })
    return <td>
      <span>{secondaryEngines.map((e) => this.engineLabel(e, false, false)).join(',')}</span>
    </td>
  }

  private viewPromptsCell = (props: any) => {
    return <td>
      <Button onClick={() => this.setState({
        selectedPrompt: props.dataItem.id,
        selectedEngine: props.dataItem.engines[0].id,
        editableTemplates: this.toEditableTemplates(props.dataItem.engines[0].templates)
      })}>
        Show Prompts
      </Button>
    </td>
  }

  protected toEditableTemplates = (templates: PromptTemplate[]): EditablePromptTemplate[] => {
    return templates.map((t) => {
      return {
        ...t,
        isValid: true,
        invalidFields: [],
        changedFields: []
      }
    })
  }

  protected previewModal = () => {

    const selectedPrompt = this.state.promptTypes.filter((p: PromptType) => p.id === this.state.selectedPrompt)[0] || NO_PROMPT_TYPE
    const selectedEngine = selectedPrompt.engines.filter((e: PromptEngine) => e.id === this.state.selectedEngine)[0] || selectedPrompt.engines[0]

    return <div className="generate-email-modal">
        <Modal isOpen={this.state.selectedPrompt >= 0} className="generate-email-modal">
          <ModalBody>
            <Row>
              <h3>{selectedPrompt.name}</h3>
            </Row>
            <Row>
              {selectedPrompt.description}
            </Row>
          { selectedPrompt.engines.length > 1 && !this.state.editingTemplates
          ?
            <Row>
              <DropdownComponent data={selectedPrompt.engines.map((e: PromptEngine) => {
                  return {
                    description: this.engineLabel(e, true, false),
                    key: e.id
                  }
                  })}
                selected={selectedEngine.id}
                onChange={(e: OnChangeEvent) => this.setState({ 
                  selectedEngine: e.selected.key as number,
                })}
              />
            </Row>
          :
            <Row className="engine-label">
              <span>Engine: {this.engineLabel(selectedEngine, false, false)}</span>
            </Row>
          }
            <Row>
              <Grid data={this.state.editableTemplates}>
                <GridColumn field="sequence" title="Sequence" cell={this.editTemplateCell}/>
                <GridColumn field="prompt" title="Prompt" cell={this.editTemplateCell}/>
                <GridColumn field="options" title="Options" cell={this.editTemplateCell}/>
              </Grid>
            </Row>
          { this.state.editingTemplates ?
            <Row>
              <Button onClick={() => this.addTemplate()}>Add Template</Button>
            </Row>
          : null }
          { this.state.editingTemplates && !selectedEngine.is_default ? 
            <Row>
              <span>Make Default?</span>
              <input type="checkbox" />
            </Row>
            : null
          }
          </ModalBody>
          <ModalFooter>
            <Button disabled={this.state.editingTemplates} onClick={() => this.setState({
              editingTemplates: true
            })}>
              Edit
            </Button>
            <Button disabled={!this.validTemplates()} onClick={this.saveTemplates} >
              Save
            </Button>
            <Button onClick={() => this.setState({ 
              selectedPrompt: -1, 
              selectedEngine: -1, 
              editableTemplates: [],  
              editingTemplates: false
            })}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>

  }

  private addTemplate = () => {
    this.setState({
      editableTemplates: this.state.editableTemplates.concat([{
        id: this.state.editableTemplates.length,
        sequence: 0,
        prompt: '',
        options: '',
        changedFields: [],
        invalidFields: [],
      }])
    })
  }

  private saveTemplates = () => {
    return this.context.entityMetadata
     .getEntityObject('aiPromptType')
     .then((entity) => {
       entity.setRecordId(this.state.communityId, this.state.selectedEngine)
       entity.data.aiEngineId = this.state.selectedEngine
       entity.data.templates = this.state.editableTemplates
       return entity.save().then(() => {
          this.setState({
            editingTemplates: false,
            editableTemplates: [],
          })
       })
     })
  }

  private validTemplate = (template : EditablePromptTemplate): boolean => {
    return template.invalidFields.length === 0
  }

  private validTemplates = (): boolean => {
    return this.state.editableTemplates.every((t: EditablePromptTemplate) => this.validTemplate(t))
  }

  private editTemplateCell = (props: any) => {
    const className = (props.dataItem.invalidFields && props.dataItem.invalidFields.includes(props.field)) ? 'invalid' 
      : (props.dataItem.changedFields && props.dataItem.changedFields.includes(props.field)) ? 'changed' : '' 
    return <td className={className}>
      {this.state.editingTemplates
       ? <Input className={className}
                value={props.dataItem[props.field]}
                onChange={(e) => { this.editTemplateValue(props, e) }}/>
       : <span>{props.dataItem[props.field]}</span>
      }
    </td>
  }

  private editTemplateValue = (props: any, event: any) => {
    this.setState({
      editableTemplates: this.state.editableTemplates.map((et: EditablePromptTemplate) => {
        if (et.id === props.dataItem.id) {
          et[props.field] = event.target.value
          if (!et.changedFields) et.changedFields = []
          et.changedFields.push(props.field)
          if (!this.validateData(props.field, event.target.value)) {
            if (!et.invalidFields) et.invalidFields = []
            et.invalidFields.push(props.field)
          }
        }
        return et
      }),
    })
  }

  private validateData = (field: string, value: string): boolean => {
    if (field === 'sequence' ) {
      try {
        parseInt(value, 10)
      } catch (e) {
        return false
      }
    } 
    if (field === 'options') {
      try {
        JSON.parse(value)
      } catch (e) {
        return false
      }
    }
    return value.length > 0
  }
}

export const RexPrompts = GenericRedux.createConnect(
  RexPromptsComponent,
  "rex_prompts",
)
