import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import * as Flash from 'components/flash'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { emailSeparator } from 'generic/utility'
import { isEmpty } from 'lodash'
import React from 'react'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { PREVIEW_MODE } from 'shared/templates/constants'
import { formatDateTimeForLambda } from 'shared_server_client/dates'
import {
  ACTIVE_COMMUNITY_NOT_FOUND_ERROR,
  EMAIL_SENT_FAILURE,
  EMAIL_SENT_SUCCESSFULLY,
  EmailAttributes, getHashPreview, getMenuSectionPreview, isValidTabPreview, MenuIcons,
  PreviewMenuSections,
  RETRY_LIMIT,
  RETRY_WAIT_TIME,
} from './constants'
import { SendPreview } from './edit-sections/sendPreview'
import * as Utils from './utils'
import { Dataset } from '../../generic/dataset';
import classnames from 'classnames';
import { TAB_SELECTED } from '../../store';
import { NewsLetterSetting } from './preview/articleSetting'
import { BaseClientEntity } from '../../generic/baseClientEntity'
import { SendFromNewsletter } from './preview/sendFormNewsletter'
import { DashboardMenuOption } from '../dashboard-menu/constants'

interface IEmailTemplatePreview {
  previewDate?: Date,
  segmentCode?: string,
}
interface EmailTemplatePreviewState {
  articlePreviewError: string,
  communityId: string,
  content: [],
  context?: any,
  changes: any,
  dirtySections?: any,
  emailLayoutId: number,
  emailTemplateId: string,
  htmlPreview: string,
  sendTo: string,
  community_partner?: any,
  communityRecordId?: any,
  isHtmlPreviewLoading: boolean,
  segmentCode?: any,
  selected?: PreviewMenuSections,
  configData?: any,
  isSuperUser: boolean,
  previewDate: Date,
  retryCount: number,
  validSections: any,
}

type EmailTemplatePreviewComponentProps = GenericRedux.AllComponentProps<any> & {modals: any, openModal: any}

const EditNavigation = ({ icon, section, onClick, selected, isLastElement, isValid, isDirty }) => {
  const cssClassNames = [
    'design-menu',
    'clickable-item',
    'menu-item',
  ]
  const iconClassNames = [
    'pencil',
  ]
  if ( selected ) {
    cssClassNames.push('selected')
  }
  if ( isLastElement ) {
    cssClassNames.push('extra-margin')
  }
  if ( isDirty ) {
    iconClassNames.push('fa fa-pencil')
  }
  if ( !isValid ) {
    cssClassNames.push('section-invalid')
  }
  return <div className={classnames(cssClassNames)} onClick={() => onClick(section)}>
    {icon}
    {section}
    <i className={classnames(iconClassNames)}></i>
  </div>
}

export class EmailTemplatePreviewComponent extends RasaReactComponent<EmailTemplatePreviewComponentProps, EmailTemplatePreviewState> {
  public static contextType = RasaContext
  private emailLayoutId: number = null
  private sharedStore: SharedStore
  constructor(props: any) {
    super(props, 'email_layout')
    this.state = {
      articlePreviewError: '',
      communityId: '',
      content: [],
      changes: {},
      dirtySections: {},
      emailLayoutId: 0,
      emailTemplateId: '',
      htmlPreview: '',
      isDirty: false,
      isSaving: false,
      isHtmlPreviewLoading: true,
      isLoading: true,
      sendTo: '',
      community_partner: [],
      isSuperUser: false,
      previewDate: null,
      retryCount: 1,
      validSections: {
      },
    }
  }
  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    this.fetchActiveCommunity()
  }

  public componentWillUpdate = (nextProps, nextState) => {
    if (nextState.isDirty) {
      this.props.setLeavePageWarning({
        leaveWarningMessage: 'If you leave, you will lose your unsaved changes.  Continue?',
        skipUrls: this.getEmalLayoutUrlsList(),
      })
    } else {
      this.props.setLeavePageWarning({
        leaveWarningMessage: '',
      })
    }
  }
  public render() {
    return (
      <div className="email-design">
        {this.state.isLoading ? <Loading size="32"/> :
          <div className="email-container email-preview">
            <div className="preview" style={{backgroundColor: this.props[EmailAttributes.emailBodyBackgroundColor]}}>
              {this.state.isHtmlPreviewLoading ?
                <Loading size="32"/> :
                <div className="preview-html" dangerouslySetInnerHTML={{
                  __html: this.state.htmlPreview,
                }}/>}
            </div>
            <div className="settings">
              <div className="editor">
                <div className="segment-code-container">
                  <LoadSegmentCodes setSegmentCode={this.setSegmentCode} setSegmentData={this.setSegmentData}/>
                </div>
                {this.state.isSuperUser ? this.getEditor() :
                  <SendPreview data={this.props}
                     dirty={this.state.isDirty}
                     sendNewsletter={this.sendNewsletter}
                     sendTo={this.state.sendTo}
                     changeSendTo={this.changeSendTo}
                     name={PreviewMenuSections.sendPreview}
                     previewDateChange={this.previewDateChange}
                     articlePreviewError={this.state.articlePreviewError}
                     onChange={(field: string, value: string) => this.propertyChanged(field, value)} />
                }
              </div>
            </div>
            {this.state.isSuperUser && <div className="menu">
              {this.menuToDisplay().map((section: PreviewMenuSections, i: number) =>
                <EditNavigation key={section}
                  isLastElement={i === 2}
                  isValid={this.isSectionValid(section)}
                  section={section}
                  icon={MenuIcons[section] || ''}
                  isDirty={this.isSectionDirty(section)}
                  selected={section === this.state.selected}
                  onClick={this.selectItem}/>
              )}
            </div>
            }
          </div>
        }
      </div>)
  }

  public mapPropsToEntityObject(entityObject: BaseClientEntity): void {
    Object.keys(this.state.changes).forEach((key: string) => {
      entityObject.data[key] = this.state.changes[key]
    })
  }
  public propertyChanged(field: string, value: string) {
    super.propertyChanged(field, value)
    if (this.state.selected) {
      this.setState({
        dirtySections: {
          ...this.state.dirtySections,
          [this.state.selected]: {
            isDirty: true,
          },
        },
      })
    }
    this.setState({
      changes: {
        ...this.state.changes,
        [field]: value,
      },
    })
  }

  private allSectionsValid = (): boolean => {
    return Object.keys(this.state.validSections).reduce(
        ((result, key) => result && this.state.validSections[key].isValid), true)
  }

  private getEmalLayoutUrlsList = () => {
    return Object.keys(PreviewMenuSections).map((key: string) => DashboardMenuOption.preview + '?#' + key.toLowerCase())
  }
  private isSectionValid = (section: string): boolean => {
    const sectionState  = this.state.validSections[section]
    return !sectionState || sectionState.isValid
  }
  private setSectionValid = (key: string, valid: boolean) => {
    this.setState({ isDirty: valid })
    this.setState({
      validSections: {
        ...this.state.validSections,
        [key]: {
          ...this.state[key],
          isValid: valid,
        },
      },
    })
  }
  private isSectionDirty = (section: string): boolean => {
    const sectionState  = this.state.dirtySections[section]
    return sectionState && sectionState.isDirty
  }

  private fetchActiveCommunity = () => {
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.role),
      this.sharedStore.getValue(SharedKeys.hash),
    ])
      .then(([activeCommunity, person, role, hash]) => {
        if (!activeCommunity.communityInfo || !activeCommunity.communityInfo.data) {
          if (this.state.retryCount <= RETRY_LIMIT) {
            setTimeout(() => {
              this.setState({
                retryCount: this.state.retryCount + 1,
              }, () => {
                this.fetchActiveCommunity()
              })
            }, RETRY_WAIT_TIME)
          } else {
            this.setState({
              isLoading: false,
            }, () => {
              this.context.store.dispatch(Flash.showFlashError(ACTIVE_COMMUNITY_NOT_FOUND_ERROR))
            })
          }
        } else {
          this.emailLayoutId = activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].id
          const nextIssueDate = activeCommunity.nextIssue ? activeCommunity.nextIssue.date : null
          let previewDate = new Date()
          if (nextIssueDate) {
            // there is nextIssueDate available then use nextIssueDate as previewDate
            previewDate = new Date(nextIssueDate)
          }
          this.loadRecord(activeCommunity.communityId, this.emailLayoutId)
            .then(() => {
              this.setState({
                communityId: activeCommunity.communityId,
                communityRecordId: activeCommunity.data.community_id,
                emailTemplateId: activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].value,
                isLoading: false,
                previewDate,
                selected: (hash && isValidTabPreview(hash)) ? getMenuSectionPreview(hash) : PreviewMenuSections.sendPreview,
                sendTo: person.email,
                isSuperUser: role === Roles.super_admin,
              }, () => {
                this.loadEmailTemplatePreview({previewDate})
                this.getArticlesData()
              })
            })
        }
      })
  }
  private previewDateChange = (previewDate: Date) => {
    const emailPreview: IEmailTemplatePreview = {
      previewDate,
      segmentCode: this.state.segmentCode ? this.state.segmentCode.value : null,
    }
    this.setState({
      previewDate,
    })
    this.loadEmailTemplatePreview(emailPreview)
  }
  private changeSendTo = (value: string) => {
    return this.setState({ sendTo: value })
  }

  private getArticlesData = () => {
    this.getArticleList().then((articleList) => {
      this.setState({
        content: articleList
      })
    })
  }

  private getArticleList = () => {
    const params = [{
      param: 'isActive',
      value: 1,
    }]
    return new Dataset().loadCommunityDataset('communityUpcomingContentPool', this.state.communityId, params)
      .then((r1) => {
        const articleList = r1[0].filter((e: any) => e.is_active)
        if (articleList.length === 0) {
          return new Dataset().loadCommunityDataset('communityArticleHistory', this.state.communityId, null, 50)
            .then((r2) => r2[0])
        } else {
          return articleList
        }
      })
  }

  private getEditor = () => {
    if (this.state.selected) {
      switch (this.state.selected) {
        case PreviewMenuSections.sendPreview:
          return <SendPreview data={this.props}
            dirty={this.state.isDirty}
            sendNewsletter={this.sendNewsletter}
            sendTo={this.state.sendTo}
            changeSendTo={this.changeSendTo}
            name={PreviewMenuSections.sendPreview}
            previewDateChange={this.previewDateChange}
            articlePreviewError={this.state.articlePreviewError}
            onChange={(field: string, value: string) => this.propertyChanged(field, value)}/>
        case PreviewMenuSections.sendFrom:
          return <SendFromNewsletter
            data={this.props}
            modals={this.props.modals}
            closeModal={this.props.openModal}
            isDirty={this.isDirty}
            setSectionValid={this.setSectionValid}
            articles={this.state.content}
            name={PreviewMenuSections.sendFrom}
            onChange={(field: string, value: string) => this.propertyChanged(field, value)}
            saveLayout={() => this.saveLayout(this.emailLayoutId)}
          />
        case PreviewMenuSections.newsLetterSettings:
          return <NewsLetterSetting
            data={this.props}
            name={PreviewMenuSections.newsLetterSettings}
            setSectionValid={this.setSectionValid}
            isDirty={this.isDirty}
            onChange={(field: string, value: string) => this.propertyChanged(field, value)}
            saveLayout={() => this.saveLayout(this.emailLayoutId)}
          />
      }
    }
    return <div>No Editor Found</div>
  }

  private selectItem = (item: PreviewMenuSections, context: any = {}) => {
    if (Object.keys(this.props || {}).length) {
      this.setState({
        context,
        selected: item,
      })
      this.context.store.dispatch({ type: TAB_SELECTED, hash: getHashPreview(item) })
    }
  }

  private menuToDisplay = () => {
    return [PreviewMenuSections.sendPreview, PreviewMenuSections.sendFrom, PreviewMenuSections.newsLetterSettings]
  }

  private sendNewsletter = (email: string, date: Date = null) => {
    const separator = emailSeparator(email)
    if (!isEmpty(separator)) {
      email.split(separator).forEach((emailId) => {
        if (!isEmpty(emailId)) {
          this.sendEmail(emailId.trim(), date)
        }
      })
    } else {
      this.sendEmail(email, date)
    }
  }

  private sendEmail = (email: string, date: Date = null) => {
     const payload: any = {
       CommunityIdentifier: this.state.communityId,
       OverrideEmail: email,
       UseTemplateCache: false,
       sendModeDescription: PREVIEW_MODE.description,
     }
     if (this.state.segmentCode && this.state.segmentCode.value) {
      payload.segmentCode = this.state.segmentCode.value
    }
     if (date) {
       // FeatureDate has to be a community localized UTC datetime string
       payload.FeatureDate = formatDateTimeForLambda(date)
     }

     Utils.sendEmail(this.state.communityId, email, payload, date)
      .then((success) => {
        this.context.store.dispatch(Flash.showFlashMessage(EMAIL_SENT_SUCCESSFULLY))
      })
      .catch((error) => {
        this.context.store.dispatch(Flash.showFlashError(EMAIL_SENT_FAILURE))
      })
  }

  private loadEmailTemplatePreview(emailPreview: IEmailTemplatePreview) {
    this.setState({
      isHtmlPreviewLoading: true,
    })

    const url: string = `${AjaxWrapper.getServerUrl()}/email/preview/${this.state.communityId}/${this.state.emailTemplateId}`

    const payload: any = {
      issueId: -1,
      sendModeDescription: PREVIEW_MODE.description,
    }
    if (emailPreview.previewDate) {
      payload.featureDate = formatDateTimeForLambda(emailPreview.previewDate)
    }
    if (emailPreview.segmentCode) {
      payload.segmentCode = emailPreview.segmentCode
    }
    return AjaxWrapper.ajax(url, HttpMethod.POST, payload)
      .then((response) => {
        this.setState({
          isHtmlPreviewLoading: false,
          articlePreviewError: response.data.error,
          htmlPreview: response.data.body,
        })
      })
      .catch((error) => {
        this.setState({
          htmlPreview: error.message,
          isHtmlPreviewLoading: false,
        })
      })
  }

  private setSegmentData = (data: any) => {
    this.setState({
      community_partner: data,
    })
  }

  private saveLayout = (recordId: number) => {
    if (this.isDirty && this.allSectionsValid()) {
      this.setState({
        isSaving: true
      }, () => {
        this.saveRecord(recordId).then(() => {
          window.location.reload()
        })
      })
    }
  }

  private setSegmentCode = (code: any) => {
    this.setState({
      segmentCode: code,
    })
    const emailPreview: IEmailTemplatePreview = {
      previewDate: this.state.previewDate,
      segmentCode: code.value,
    }
    this.loadEmailTemplatePreview(emailPreview)
  }
}

export const EmailTemplatePreview = GenericRedux.registerNewComponent<any>(
  EmailTemplatePreviewComponent,
  'email_preview',
  {})
