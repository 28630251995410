import { AccountAndBilling } from 'components/icons/account-and-billing'

import { PanelBarOption } from 'components/panel-bar/types'
import { AccountSettings } from 'components/settings-editor/account/account'
import { AccountBilling } from 'components/settings-editor/account/billing/home'
import { MobileAccountDetails } from 'components/settings-editor/account/billing/mobile/account'
import { NewslettersComponent } from 'components/settings-editor/account/newsletters'
import { CompanySettings } from 'components/settings-editor/newsletter/company'
import { LanguagesSettings } from 'components/settings-editor/newsletter/languages'
import { SettingsEmailPreferences } from 'components/settings-editor/newsletter/settingsEmailPreferences'
import { SettingsIntegrations } from 'components/settings-editor/newsletter/settingsIntegrations'
import { SettingsIntegrationsConfig } from 'components/settings-editor/newsletter/settingsIntegrationsConfig'
import { SettingsTeam } from 'components/settings-editor/newsletter/settingsTeam'
import { SettingsProfile } from 'components/settings-editor/profile/profile'
import { SettingsDomainAuthentication } from 'components/settings-editor/settingsDomainAuthentication'
import { AccountDiscoveryComponent } from 'components/settings-editor/account/discover'

import React from 'react'
import {
  BillingPlan,
  BillingPlanDetailCode,
  canAccessPlanFeature,
  getMaximumNumberOfNewsletters
} from "../../shared_server_client/types/billing_plan";
import {UNLIMITED_VALUE} from "../../shared_server_client/constants";
import { TechnicalSetting } from '../../components/icons/technicalSetting'
import { AccountAndBillingSetting } from '../../components/icons/accountAndBillingSetting'
import { NewsLetterSetting } from '../../components/icons/newsLetterSetting'
import { Preferences } from '../../components/icons/preferences'

const ACCOUNT_SETTINGS_CATEGORY: string = 'Account and Billing'
const NEWSLETTER_SETTINGS_CATEGORY: string = 'Newsletter Settings'
const PREFERENCES_SETTINGS_CATEGORY: string = 'Preferences'
const TECHNICAL_SETTINGS_CATEGORY:  string = 'Technical Settings'

const accountAndBilling = <AccountAndBilling/>
const accountAndBillingSetting = <AccountAndBillingSetting svgwidth={30} svgheight={30} />
const newsLetterSetting = <NewsLetterSetting svgwidth={30} svgheight={30} />
const preference = <Preferences svgwidth={30} svgheight={30} />
const technicalSetting = <TechnicalSetting svgwidth={30} svgheight={30} />

export const ACTIVE_COMMUNITY_NOT_FOUND_ERROR = 'Something went wrong. Active communityInfo doesn\'t found'
export const RETRY_LIMIT = 2
export const RETRY_WAIT_TIME = 5000
export const allSections: PanelBarOption[] = [
  {
    category: ACCOUNT_SETTINGS_CATEGORY,
    component: SettingsProfile,
    icon:  accountAndBillingSetting,
    isMenu: true,
    name: 'Profile',
    route: '',
  },
  {
    category: ACCOUNT_SETTINGS_CATEGORY,
    component: AccountSettings,
    icon: accountAndBillingSetting,
    isMenu: true,
    name: 'Account',
    route: 'account',
  },
  {
    category: ACCOUNT_SETTINGS_CATEGORY,
    component: AccountBilling,
    icon: accountAndBillingSetting,
    isMenu: true,
    name: 'Billing',
    route: 'billing',
  },
  {
    category: ACCOUNT_SETTINGS_CATEGORY,
    component: NewslettersComponent,
    icon: accountAndBillingSetting,
    isMenu: true,
    name: 'Newsletters',
    route: 'newsletters',
  },
  {
    category: ACCOUNT_SETTINGS_CATEGORY,
    component: SettingsTeam,
    icon: accountAndBillingSetting,
    isMenu: true,
    name: 'Team',
    route: 'team',
  },
  {
    category: ACCOUNT_SETTINGS_CATEGORY,
    component: AccountDiscoveryComponent,
    icon: accountAndBillingSetting,
    isMenu: true,
    name: 't-rex Summary',
    route: 'discover',
    feature_name: BillingPlanDetailCode.AI_ACCOUNT_SUMMARY
  },
  {
    category: PREFERENCES_SETTINGS_CATEGORY,
    component: SettingsEmailPreferences,
    icon: preference,
    isMenu: true,
    name: 'Email Notifications',
    route: 'emailpreferences',
  },
  {
    category: TECHNICAL_SETTINGS_CATEGORY,
    component: SettingsDomainAuthentication,
    icon: technicalSetting,
    isMenu: true,
    name: 'Domain Authentication',
    route: 'domain',
  },
  {
    category: NEWSLETTER_SETTINGS_CATEGORY,
    component: CompanySettings,
    icon: newsLetterSetting,
    isMenu: true,
    name: 'Name & Company',
    route: 'company',
  },
  {
    category: NEWSLETTER_SETTINGS_CATEGORY,
    component: SettingsIntegrations,
    icon: newsLetterSetting,
    isMenu: true,
    name: 'Integrations',
    route: 'integrations',
  },
  {
    category: NEWSLETTER_SETTINGS_CATEGORY,
    component: SettingsIntegrationsConfig,
    hide: true,
    icon: newsLetterSetting,
    isMenu: true,
    name: 'Integrations Configuration',
    route: 'integrations/config',
  },
  {
    category: NEWSLETTER_SETTINGS_CATEGORY,
    component: LanguagesSettings,
    icon: newsLetterSetting,
    isMenu: true,
    name: 'Languages',
    route: 'languages',
  },
]

export const mobileSection: PanelBarOption[] = [
  {
    category: '',
    component: MobileAccountDetails,
    icon: accountAndBilling,
    name: 'Account',
    route: 'account',
    hide: true,
  },
]

export const sections = (isSuperAdmin: boolean, isUserAdmin: boolean, billingPlan: BillingPlan): PanelBarOption[] => {
  const maximumNumberOfNewsletter: number = getMaximumNumberOfNewsletters(billingPlan)
  return  allSections.filter((item) => {
    if ( isSuperAdmin ) {
      return true
    }
    //TODO::It should be in the include in the each plan feature instead of implementing the name base condition
    if (item.name === 'Newsletters') {
      item.hasFeature = isUserAdmin || maximumNumberOfNewsletter > 1 || maximumNumberOfNewsletter === -1 || maximumNumberOfNewsletter === UNLIMITED_VALUE;
      return true
    }
    if (item.feature_name) {
      item.hasFeature = canAccessPlanFeature(item.feature_name, billingPlan)
      return true
    }
    if (item.category === ACCOUNT_SETTINGS_CATEGORY) {
      return isUserAdmin || item.name === 'Profile'
    } else {
      return true
    }
  })
}