import { DropdownOption } from 'components/dropdown/component'
import zip from 'lodash/fp/zip'

export const NO_DATA_COPY = 'There isn’t any data here right now. If you recently sent your newsletter, check back in a few hours after we crunch the numbers!'
export const REPORT_GENERATED = 'We are generating your report. You will get an email shortly.'
export const INTERVAL_THRESHOLD = 15
export const SocialMediaTypes = {
  LinkedIn: 'linkedin',
  Twitter: 'twitter',
  X: 'x',
  Facebook: 'facebook',
}
export interface SocialMediaShare {
  text: string,
  title: string,
  type: string,
  url: string,
  user: any,
}
export type ShareGenerator = (url: string, text: string, user: any) => SocialMediaShare
export interface SocialMediaMaker {
  generator: ShareGenerator
  type: string,
}

export const ALL_CLICKS_KEY = 'all_clicks'
export const REAL_CLICKS_KEY = 'real_clicks'
export const ONLY_SUSPECT_CLICKS_KEY = 'suspect_clicks'
export const DOC_COUNT = 'doc_count'

export enum SourceFilterOption {
  // Important that default also be 0 - so "not specified" is same as "0" as "falsey" values
  // And these should be strings - since that's what the underlying properties are
  ALL_TAGS = '0',
  RASA_TAGS = '1',
  CUSTOM_TAGS = '2',
}

export enum ContentFilterOption {
  DOMAIN = 'domain',
  REQUEST_SOURCE = 'request_source',
  REQUEST_TYPE = 'request_type',
  STATUS_CODE = 'status_code'
}

export const SourceFilters: DropdownOption[] = [
  {
    description: 'All Tags',
    key: SourceFilterOption.ALL_TAGS,
    value: SourceFilterOption.ALL_TAGS,
  },
  {
    description: 'rasa.io Tags',
    key: SourceFilterOption.RASA_TAGS,
    value: SourceFilterOption.RASA_TAGS,
  },
  {
    description: 'Your Tags',
    key: SourceFilterOption.CUSTOM_TAGS,
    value: SourceFilterOption.CUSTOM_TAGS,
  },
]

export const ContentFilters: DropdownOption[] = [
  {
    description: 'Domain',
    key: '1',
    value: ContentFilterOption.DOMAIN,
  },
  {
    description: 'Request source',
    key: '2',
    value: ContentFilterOption.REQUEST_SOURCE,
  },
  {
    description: 'Request type',
    key: '3',
    value: ContentFilterOption.REQUEST_TYPE,
  },
  {
    description: 'Status code',
    key: '4',
    value:ContentFilterOption.STATUS_CODE,
  }
]
export const AnalyticsFilterKeys = {
  SELECTED_DATE_RANGE: 'dateRange',
  SELECTED_SOURCE: 'source',
  SELECTED_CONTENT_BLOCK: 'content_block',
  SELECTED_INTERVAL: 'interval',
  SELECTED_DIMENSION: 'dimension',
  SELECTED_METRIC: 'metric',
  SELECTED_SEGMENT: 'segment',
  SELECTED_SUSPECT_CLICK: 'suspect_click',
}

export enum REPORT_NAMES {
  DAILY_STATS = 'Daily Stats',
  OPEN_CLICKS = 'Open/Clicks',
  EMAIL_HEALTH= 'Email Health Report',
  SEND_SUMMARIES = 'Send Summaries',
  TOPICS = 'Topics',
  SOURCES = 'Sources',
  ARTICLES = 'Articles',
  OTHER_CLICKS = 'Other Clicks',
  LINK_REPORTS = 'Link Reports',
  REPORTS = 'Reports',
  SEGMENTATION = 'Segmentation Report',
  IMAGES = 'Images',
  AI_KNOWLEDGE_TIER = 'AI Knowledge Tier',
  URL_REQUESTS = 'URL Requests',
}

export const SOURCE_FAILURE_REASONS = [
  {
    type: 'Timeout',
    reasons: ['timed out', 'timer expired'],
  },
  {
    type: 'Internal Error',
    reasons: ['pymysql', 'internal server error'],
  },
  {
    type: 'Invalid XML',
    reasons: ['is not an xml', 'not well-formed', 'mismatched tag'],
  },
  {
    type: 'Service Unavailable',
    reasons: ['service temporarily unavailable'],
  },
  {
    type: 'Redirect',
    reasons: ['permanent redirect'],
  },
]

export const TIERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
]

export const KNOWLEDGE_TIER_RANGES_BY_LABEL = {
  TIER_A_TO: 1,
  TIER_B_FROM: 1,
  TIER_B_TO: 5,
  TIER_C_FROM: 5,
  TIER_C_TO: 10,
  TIER_D_FROM: 10,
  TIER_D_TO: 25,
  TIER_E_FROM: 25,
  TIER_E_TO: 50,
  TIER_F_FROM: 50,
  TIER_F_TO: 100,
  TIER_G_FROM: 100,
  TIER_G_TO: 200,
  TIER_H_TO: 200,
  TIER_H_FROM: 300,
  TIER_I_FROM: 300,
}
export const KNOWLEDGE_TIER_RANGES = [
  {
    to: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_A_TO,
  },
  {
    from: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_B_FROM,
    to: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_B_TO,
  },
  {
    from: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_C_FROM,
    to: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_C_TO,
  },
  {
    from: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_D_FROM,
    to: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_D_TO,
  },
  {
    from: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_E_FROM,
    to: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_E_TO,
  },
  {
    from: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_F_FROM,
    to: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_F_TO,
  },
  {
    from: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_G_FROM,
    to: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_G_TO,
  },
  {
    from: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_H_FROM,
    to: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_H_TO,
  },
  {
    from: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_I_FROM,
  },
]

export const TIER_LABELS = zip(TIERS, KNOWLEDGE_TIER_RANGES)
  .map(([tier, range]) =>
    !range.from ? `${tier}` :
      !range.to ? `${tier}` :
        `${tier}`)

export const CHART_COLORS = [
  "#b7de30",
  "#71BDE9",
  "#EDCE00",
  "#ed0000",
  "#00ed43",
  "#bf3cea",
  "#1afff7",
  "#5c009a",
  "#b95e5e",
  "#FF9000",
]

export const PROGRESS_STATUS = {
  EXCELLENT: 'Excellent',
  GOOD: 'Good',
  NEEDS_WORK: 'Needs Work',
  OKAY: 'Okay',
  POOR: 'Poor',
  VERY_GOOD: 'Very Good'
}

export const OTHER_COLOR = '#ff631a'

export const ANALYTICS_TOPICS_LIMIT = '100'
export const SEGMENTATION_AGGREGATION_BUCKET_SIZE = '1000'
export enum MetricScoreOperation {
  GREATER = 'greater',
  LESS = 'less',
}
