// parallel to ChatCompletionRequestMessage, but without openai dependency
export interface ChatPrompt {
  content: string,
  role: string,
}

export interface AnalyzePayload {
  content: string,
  title: string,
  url: string,
}

export enum AnalyzeType {
  RSS = 'RSS Feed',
  URL = 'article',
}

export interface AnalyzeOptions {
  includeSummary?: boolean,
  source?: string,
  topicCount?: number,
  type: AnalyzeType,
  wordCount?: number,
}

export interface Keyword {
  id?: string | number,
  source: string,
  text: string,
  weight?: number,
}

export interface AnalyzeResponse {
  error?: string,
  categories?: Keyword[],
  prompts?: ChatPrompt,
  summary: string,
  topics: Keyword[],
}

export interface ChatPayload extends AnalyzePayload {
  aiPromptType: string,
  emojis: number,
  includeBackground?: boolean,
  language: string,
  length: number,
  prompt: string | string[],
  promptType: string,
  tone: string,
  topics?: string[],
}

export interface ChatResponse {
  done?: boolean
  error?: string,
  message: string,
  prompts?: ChatPrompt[],
  session_id?: string,
}

export type ChatCallback = (chunk: ChatResponse) => void

export interface AnimatePayload {
  imageDuration: number,
  imageHeight: number,
  imageWidth: number,
  videoUrl: string,
}

export interface AnimateResponse {
  imageUrl: string,
}

export enum GenerationType {
  SUMMARY = 'summary',
  TEXT = 'text',
}


export enum PromptType {
  ANALYZE_ARTICLE = "analyze_article",
  ARTICLE_PREVIEW_TEXT = "article_preview_text",
  GENERATE_TEXT = "generate_text",
  HELPING_CONTENT = "helping_content",
  PHISHING_CHECK = "phishing_check",
  REX_ADD_ARTICLE_COMMAND = "rex_add_article_command",
  REX_CONFIRMATION = "rex_confirmation",
  REX_ARTICLE_IMAGE_GENERATION = "rex_article_image_generation",
  REX_PROMPT_ANALYZE = "rex_prompt_analyze",
  REX_SUGGESTING_CONTENT = "rex_suggesting_content",
  SUMMARIZE_ARTICLE = "summarize_article",
  REX_HELP_URL_SUMMARIZE = "rex_help_url_summarize",
  REX_GENERATE_EMAIL = "rex_generate_email",
  REX_ANALYZE_ACCOUNT = "rex_analyze_account",
  REX_ANALYZE_IMAGE_COLOR = "rex_analyze_image_color",
}
