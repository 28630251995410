import * as Flash from 'components/flash'
import { InputSearch } from 'components/input-search/component'
import { CopyInput } from 'components/input/component'
import { isEmpty} from 'lodash'
import React from 'react'
import { Col } from 'reactstrap'
import * as GlobalConstants from '../../../../constants'
import * as Constants from '../../constants'
import * as Integrations from '../integrations'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

declare const RASA_SELF_SERVICE_API: string
declare const RASA_IMAGE_API_ENDPOINT: string

export class HubSpotConfigRender extends IntegrationConfigRenderBase {
  protected showContactsCount = false

  protected initializeCustomProps() {
    this.props.propertiesChanged({
      loadSegmentField: true,
      segmentFieldLabel: 'Segment Code Field',
    })
  }

  protected getContacts(communitySystemId: number, selectedFilterId: string, selectedFilterName: string) {
    // not supported
  }

  protected renderFinalizeHtml() {
    return <div>
      {this.renderSelectFieldNames()}
      {this.renderFinalizeConfirmation()}
      {this.renderFinalizeConnection()}
    </div>
  }

  protected buildPopUpUrl(): string {
    const sysName: string = this.props.data.systemName
    const scopes: string = this.props.data.systemMetadata.scopes.join(' ')
    const optional_scopes: string = this.props.data.systemMetadata.optional_scopes ? this.props.data.systemMetadata.optional_scopes.join(' ') : ''
    let decodedState = this.communityId
    if (this.isRichIntegration()) {
      decodedState += `|rich_integration`
    }
    const encodedState = btoa(decodedState)
    return `${this.systemInfo.keyUrl}?scope=${scopes}&optional_scope=${optional_scopes}&state=${encodedState}&client_id=${this.props.clientIds[sysName]}&redirect_uri=${RASA_SELF_SERVICE_API}/integrations/${sysName.toLowerCase()}-oauth-callback`
  }

  protected renderFilterDropdown() {
    return <InputSearch
      placeholder= "Lists"
      data = { this.getDropdownData(this.state.filters, Constants.HubsportSourceType.LISTTYPE)}
      type="search"
      isDisabled={false}
      value={this.selectedListType(this.state.filters,
        this.props.data.selectedFilterId,
        Constants.HubsportSourceType.LISTTYPE)}
        textFiled="name"
        onChange={(e) => this.onListTypeChange(e)}/>
  }

  protected onTwoWayCheckboxChange(isChecked) {
    const filterInfo = Integrations.getFilterInfo(this.props.data)
    const filterName = filterInfo.name
    const filterId = filterInfo.id
    const newSystemApiAttribute = {
      ...JSON.parse(this.props.data.system_api_attribute),
      sync_direction: isChecked ? 'both' : 'to_rasa',
    }

    newSystemApiAttribute[filterName] = this.props.data.selectedFilterName
    newSystemApiAttribute[filterId] = this.props.data.selectedFilterId
    newSystemApiAttribute[Integrations.SubscriptionField.subscription_type_name] = ''
    newSystemApiAttribute[Integrations.SubscriptionField.subscription_type_id] = ''

    this.props.propertiesChanged({
      fieldMappings: this.updateFieldMappings(this.props.data.fieldMappings, 'is_subscribed', isChecked),
      system_api_attribute: JSON.stringify(newSystemApiAttribute),
    })
    if (this.props.data.is_active) {
      this.props.saveRecord(this.props.data.id)
        .then((result) => {
          this.props.context.store.dispatch(Flash.showFlashMessage(Integrations.notificationMessages.UpdateSuccess))
        })
    }
  }

  protected validateTwoWayStatus(): boolean {
    if (this.systemInfo.twoWayStatus === Integrations.IntegrationStatus.available && this.props.data.id &&
      ((!this.props.data.is_active && this.state.authenticated) || !!this.props.data.is_active)) {
       return !(this.props.data.is_active && this.props.data.selectedFilterName)
    } else {
      return false
    }
  }

  protected isFinalizeConnectionEnabled() {
    return super.isFinalizeConnectionEnabled() && (!this.isTwoWayChecked() ||
    !isEmpty(JSON.parse(this.props.data.system_api_attribute).subscription_type_name))
  }

  protected richIntegrationCheckBoxChange(isRichSync) {
    const newSystemApiAttribute = {
      ...JSON.parse(this.props.data.system_api_attribute),
      is_full_integration: isRichSync,
    }
    this.props.propertiesChanged({
      system_api_attribute: JSON.stringify(newSystemApiAttribute),
    })
    if (this.props.data.is_active) {
      this.props.saveRecord(this.props.data.id)
        .then((result) => {
          this.props.context.store.dispatch(Flash.showFlashMessage(Integrations.notificationMessages.UpdateSuccess))
        })
    }
  }

  protected isRichIntegrationChecked() {
    return this.props.data && this.props.data.system_api_attribute
      ? JSON.parse(this.props.data.system_api_attribute).is_full_integration === true
      : false
  }

  protected getRichIntegration() {
    return this.isRichIntegration() && (this.state.authenticated || this.props.data.is_active) ?
      <div>
      <div className="outbound-confirm-checkbox-line1 hubspot-custom-field-margin">
        <p className="outbound-confirm-checkbox-text rich-checkbox-label">
          Push engagement data to Hubspot
        </p>
      </div>
      <div>
        <p className="hubspot-custom-field-margin">
          Data pushed to HubSpot will be stored in custom attributes.
          These fields will be named with your newsletter name - for example:
        </p>
        <p>
          <em>rasa.io {this.props.activeCommunity.communityName || `Newsletter ${this.props.activeCommunity.data.community_id}` } - Last Click</em>.
        </p>
        <p>
          If you want to change the name of your newsletter, first go the&nbsp;
          <span onClick={() => this.props.push('/settings/company')}>
            <a href="#">Company Settings page</a>
          </span>
          &nbsp;then come back to complete the connection.
        </p>
      </div>
    </div> : null
  }

  protected renderTwoWayStatus() {
    if (this.validateTwoWayStatus()) {
      return <div className="outbound-confirm-checkbox-wrapper">
        {Integrations.systemInformation(this.props.data.systemName).twoWayFieldNeeded && this.props.data.id &&
          <div className="outbound-confirm-checkbox-line2">
            <p className="outbound-confirm-checkbox-text">
              {this.renderTwoFieldNeededText()}
            </p>
          </div>
        }
      </div>
    }
  }

  protected validateToFinalize(): boolean {
    return (this.state.authenticated && !this.supportsFilter) || (this.state.authenticated &&
    !this.props.data.is_active && !this.state.isGettingContactsCount) ? true : false
  }

  protected renderSelectedFilters() {
    return <div>
      {this.props.data.is_active && this.props.data.selectedFilterName
      ? <div>
        {`You have successfully connected
        ${this.props.data.selectedFilterName !== Constants.ALL_ROLES
        ? ` ${this.formatedFilterDescription()}:`
        : ':'}
        `}
        <div className="selected-filter-name-wrapper"> {this.props.data.selectedFilterName}</div>
      </div>
      : null}
      {this.props.data.is_active && !isEmpty(JSON.parse(this.props.data.system_api_attribute).subscription_type_name)
      ? <div>
        <br />
        Subscription type:
        <div className="selected-filter-name-wrapper"> {JSON.parse(this.props.data
          .system_api_attribute).subscription_type_name}</div>
      </div>
      : null}
      {this.props.data.is_active && this.isFieldMappingExist('segment_code') && this.canSegmentation()
        ? <div>
          <br />
          {this.props.data.segmentFieldLabel}:
          <div className="selected-filter-name-wrapper">
            {this.getFieldMappingName('segment_code')}
          </div>
        </div>
        : null}
      {this.getRichIntegration()}
    </div>
  }

  protected checkedFinalizeCheckBox = () => {
    this.setState({ confirmed: this.props.data.selectedFilterName === '' ? false : !this.state.confirmed })
  }

  protected renderFinalizeConfirmation() {
    return <Col className="confirm-connect">
      <p className="text">You are about to sync &nbsp;
        {(this.props.data.selectedFilterName && (this.props.data.selectedFilterName === Constants.ALL_CONTACTS
          || this.props.data.selectedFilterName === Constants.ALL_SUBSCRIPTIONS
          || this.props.data.selectedFilterName === Constants.ALL_ROLES
          ))
        ? `All your ${this.props.data.systemName} ${this.props.data.selectedFilterName === Constants.ALL_SUBSCRIPTIONS ? 'subscriptions' : 'contacts'}`
        : `${this.formatedFilterDescription()} "${this.props.data.selectedFilterName}" ${this.getSubscriptionTypeName()}`}.&nbsp;
        Test Is that what you meant to do?
      </p>
      <p className="confirm">
        <input className="checkbox" type="checkbox"
          checked={this.state.confirmed}
          onChange={() => this.checkedFinalizeCheckBox()}
          id="confirm-checkbox" />
        <label htmlFor="confirm-checkbox">Yes</label>
      </p>
    </Col>
  }

  protected renderIntegrationTips() {
    return this.props.data.is_active &&
      this.isRichIntegrationChecked() ? <div className="useful-links">
      <a href="https://ecosystem.hubspot.com/marketplace/apps/marketing/email/rasa-io-206230" target="_blank">
        Visit the HubSpot marketplace to learn more about how this integration works
      </a>
      <a href={GlobalConstants.RASA_HELP_HUBSPOT_DOCUMENTATION} target="_blank">
        Read more details about the integration in our Help Center
      </a>
      <div className="hubspot-embed-notes">
        <label>Embed rasa engagement reports inside a HubSpot custom dashboard</label>
        <CopyInput link={`https://hubspot-ui.rasa.io/engagement/chart?c=${this.communityId}`} />
        <CopyInput link={`https://hubspot-ui.rasa.io/engagement/table?c=${this.communityId}`} />
         <br />
        <div className="embed-example-img">
          <img src={`${RASA_IMAGE_API_ENDPOINT}/self-service-logos/path/2023-08-04/files/ccf284a1-497c-414a-9102-f589fdfa4d4e?h=270&w=480`} />
        </div>
      </div>
    </div> : null
  }

  protected shouldRenderDisconnect(): boolean {
    return super.shouldRenderDisconnect() && (!this.isRichIntegration() || this.isRichIntegrationChecked())
  }

  protected renderConnect(): JSX.Element {
    if(this.props.data.is_active && this.isRichIntegration() && !this.isRichIntegrationChecked()){
      return <div className="connect-wrapper">
        <div className="authenticate-wrapper">
          <div className={`authenticate-btn-wrapper-${this.isAuthenticateButtonEnabled() ? '' : 'disabled'}`}>
            <div className={`save-button connect ${this.isAuthenticateButtonEnabled()
              ? 'clickable-item save-button-enabled' : ''}
                authenticate-btn-${this.isAuthenticateButtonEnabled() ? '' : 'disabled'}`}
                 onClick={() => this.connect()}> Connect
            </div>
          </div>
        </div>
      </div>
    }

    return super.renderConnect()
  }

  protected connect() {
    if (this.props.data.is_active && this.isRichIntegration() && !this.isRichIntegrationChecked()) {
      return this.richIntegrationCheckBoxChange(true)
    }
    super.connect()
  }

  private getSubscriptionTypeName() {
    if (JSON.parse(this.props.data.system_api_attribute)[Constants.SUBSCRIPTION_TYPE_NAME] !== ''
    && JSON.parse(this.props.data.system_api_attribute)[Constants.SUBSCRIPTION_TYPE_NAME] !== undefined) {
      return this.props.data.selectedFilterName ? `and Subscription "${JSON.parse(this.props.data.system_api_attribute)[Constants.SUBSCRIPTION_TYPE_NAME]}"`
      : `"${JSON.parse(this.props.data.system_api_attribute)[Constants.SUBSCRIPTION_TYPE_NAME]}"`
    } else {
      return ''
    }
  }

  private renderSelectFieldNames() {
      return <div className="hubspot-custom-field-names">
          <div className="filterSubscriptionstype">
            <div>
              Select a Subscription Type if you want to synchronize unsubscribe information with HubSpot
            </div>
            <div className="hubspot-custom-field-margin">
             <InputSearch
              placeholder= "Subscriptions"
              data = { this.getDropdownData(this.state.filters, Constants.HubsportSourceType.SUBSCRIPTIONTYPE)}
              type="search"
              isDisabled = {this.props.data.selectedFilterName === ''}
              value={this.selectedListType(this.state.filters,
                JSON.parse(this.props.data.system_api_attribute)
                .subscription_type_id, Constants.HubsportSourceType.SUBSCRIPTIONTYPE)}
                textFiled="name"
                onChange={(e) => this.onSubscriptionTypeChange(e)}/>
            </div>
          </div>
      </div>
  }

  private onListTypeChange(e) {
    const newSystemApiAttribute = {
      ...JSON.parse(this.props.data.system_api_attribute),
      sync_direction: this.isSelectedRasaType(e ? e.name : '',
      JSON.parse(this.props.data.system_api_attribute)[Integrations.SubscriptionField.subscription_type_name]),
     }
    const isTwoWayIntegration = newSystemApiAttribute.sync_direction === 'both'
    Promise.resolve(
    this.props.propertiesChanged({
      fieldMappings: this.updateFieldMappings(this.props.data.fieldMappings, 'is_subscribed', isTwoWayIntegration),
      selectedFilterName: e ? e.name : '',
      selectedFilterId: e ?  e.value : '',
      selectedFilterType: e ?  e.type : '',
      system_api_attribute: JSON.stringify(newSystemApiAttribute),
    })).then(() => {
      this.setState({confirmed: false})
    })
  }

  private onSubscriptionTypeChange(e) {
    const newSystemApiAttribute = {
     ...JSON.parse(this.props.data.system_api_attribute),
     sync_direction: this.isSelectedRasaType(this.props.data[Integrations.ListField.selected_filter_name], e ? e.name : ''),
    }
    newSystemApiAttribute[Integrations.SubscriptionField.subscription_type_name] =  e ? e.name : ''
    newSystemApiAttribute[Integrations.SubscriptionField.subscription_type_id] = e ? e.value : ''

    this.props.propertiesChanged({
      system_api_attribute: JSON.stringify(newSystemApiAttribute),
    })
  }

  private isSelectedRasaType(selectedListType: string, selectedSubscriptionType: string) {
    return selectedListType && selectedSubscriptionType ? 'both' : 'to_rasa'
  }

  private selectedListType(data, selectedValue, listType) {
    return data.find((a) => a.value === selectedValue && a.type === listType)
  }

  private getDropdownData(data, listType) {
    return data.filter((a) => a.type === listType)
  }
}
