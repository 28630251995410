import * as Flash from 'components/flash'
import React from 'react'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'
import * as Integrations from '../integrations'

declare const RASA_SELF_SERVICE_API: string

export class GrowthZoneConfigRender extends IntegrationConfigRenderBase {

  protected buildPopUpUrl(): string {
    let decodedState = this.communityId
    if (this.isRichIntegration()) {
      decodedState += `|rich_integration`
    }
    const encodedState = btoa(decodedState)
    return `https://growthzoneapp.com/oauth/authorize?client_id=${this.props.clientIds[this.props.data.systemName]}&scope=contact_data+offline_access&response_type=code&redirect_uri=${RASA_SELF_SERVICE_API}/integrations/${this.props.data.systemName.toLowerCase()}-oauth-callback&state=${encodedState}`
  }
  protected getRichIntegration() {
    return this.isRichIntegration() && (this.state.authenticated || this.props.data.is_active) ?
      <div>
        <div className="outbound-confirm-checkbox-line1 salesforce-custom-field">
          <p className="outbound-confirm-checkbox-text rich-checkbox-label">
            Push engagement data to GrowthZone
          </p>
          {this.renderIntegrationTips()}
        </div>
      </div> : <div>{this.renderIntegrationTips()}</div>
  }

  protected renderIntegrationTips() {
    return null
  }
  protected connect() {
    if (this.props.data.is_active && this.isRichIntegration() && !this.isRichIntegrationChecked()) {
      return this.richIntegrationCheckBoxChange(true)
    }
    super.connect()
  }
  protected shouldRenderDisconnect(): boolean {
    return super.shouldRenderDisconnect() && (!this.isRichIntegration() || this.isRichIntegrationChecked())
  }

  protected renderConnect(): JSX.Element {
    if(this.props.data.is_active && this.isRichIntegration() && !this.isRichIntegrationChecked()){
      return <div className="connect-wrapper">
        <div className="authenticate-wrapper">
          <div className={`authenticate-btn-wrapper-${this.isAuthenticateButtonEnabled() ? '' : 'disabled'}`}>
            <div className={`save-button connect ${this.isAuthenticateButtonEnabled()
              ? 'clickable-item save-button-enabled' : ''}
                authenticate-btn-${this.isAuthenticateButtonEnabled() ? '' : 'disabled'}`}
                 onClick={() => this.connect()}> Connect
            </div>
          </div>
        </div>
      </div>
    }

    return super.renderConnect()
  }
  protected richIntegrationCheckBoxChange(isRichSync) {
    const newSystemApiAttribute = {
      ...JSON.parse(this.props.data.system_api_attribute),
      is_full_integration: isRichSync,
    }
    this.props.propertiesChanged({
      system_api_attribute: JSON.stringify(newSystemApiAttribute),
    })
    if (this.props.data.is_active) {
      this.props.saveRecord(this.props.data.id)
        .then((result) => {
          this.props.context.store.dispatch(Flash.showFlashMessage(Integrations.notificationMessages.UpdateSuccess))
        })
    }
  }
}
